import { Component, Injector, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { AppComponentBase } from "@shared/common/app-component-base";

@Component({
  selector: "app-saving-global",
  templateUrl: "./saving-global.component.html",
  styleUrls: ["./saving-global.component.less"],
})
export class SavingGlobalComponent extends AppComponentBase implements OnInit {
  urlQuestion1: SafeUrl;
  currentDate: string = "";
  initDate: string = "";
  @Input() copyTransportTime: number = 0;
  @Input() productiveTransportTime: number = 0;
  @Input() professionalPrice: number = 0;

  constructor(injector: Injector, private domSanitizer: DomSanitizer) {
    super(injector);
    this.getDates();
  }

  ngOnInit() {
    if (this.appSession.tenantId != null)
      this.urlQuestion1 =
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `https://app1.qametrik.com/public/dashboard/b8de37de-60d4-4d42-874e-492825d4de4a?data_inicial=${this.initDate}&data_final=${this.currentDate}&tempo_transporte_c%25C3%25B3pia_(mm)=${this.copyTransportTime}&tempo_transporte_produtivo_(mm)=${this.productiveTransportTime}&valor_profissional_(hr)=${this.professionalPrice}&tenantid=${this.appSession.tenantId}#hide_parameters=tenantid&theme=transparent`
        );
    else
      this.urlQuestion1 =
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `https://app1.qametrik.com/public/dashboard/44bcb638-589d-46d8-b99e-b12cb80d7c72?data_inicial=${this.initDate}&data_final=${this.currentDate}#theme=transparent`
        );
  }

  getDates() {
    var currentDate = new Date();
    var initDate = new Date();

    initDate.setDate(initDate.getDate() - 30);

    this.currentDate = currentDate.toISOString().slice(0, 10);
    this.initDate = initDate.toISOString().slice(0, 10);
  }
}
