import { AppSessionService } from "@shared/common/session/app-session.service";

import { Injectable, Injector } from "@angular/core";
import { AppMenu } from "./app-menu";
import { AppMenuItem } from "./app-menu-item";
import { PermissionCheckerService } from "abp-ng2-module";
import { environment } from "environments/environment";
import { AppComponentBase } from "@shared/common/app-component-base";

@Injectable()
export class AppNavigationService extends AppComponentBase {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        injector: Injector,
        private _appSessionService: AppSessionService
    ) {
        super(injector);
    }

    isAIActive() {
        return (
            this.appSession.tenantId != null &&
            this._appSessionService.user.activeAiForBusiness &&
            this._appSessionService.user.aiSettingsAreActive
        );
    }

    getMenu(): AppMenu {
        return new AppMenu("MainMenu", "MainMenu", [
            new AppMenuItem(
                "Meu perfil",
                "",
                "fas fa-user",
                "/app/main/myProfile"
            ),
            new AppMenuItem(
                "Dashboard",
                "Pages.Dashboards",
                "flaticon-line-graph",
                "/app/main/dashboard"
            ),
            new AppMenuItem(
                "Relatórios",
                "Pages.Dashboards",
                "flaticon-graphic",
                "/app/main/relatorios"
            ),
            new AppMenuItem(
                "Training",
                "Pages.Administration.TrainingVideos",
                "flaticon-youtube",
                "",
                [
                    new AppMenuItem(
                        "QametrikDevopsTraining",
                        "Pages.Administration.TrainingVideos",
                        "flaticon-suitcase",
                        "/app/admin/training-videos/qametrikdevops"
                    ),
                    new AppMenuItem(
                        "AbapTuningTraining",
                        "Pages.Administration.TrainingVideos",
                        "flaticon-file-2",
                        "/app/admin/training-videos/abaptuning"
                    ),
                    new AppMenuItem(
                        "Documentação",
                        "",
                        "flaticon-file",
                        environment.gitbookUrl,
                        [],
                        true
                    ),
                ]
            ),
            new AppMenuItem(
                "Tenants",
                "Pages.Tenants",
                "flaticon-list-3",
                "/app/admin/tenants"
            ),
            new AppMenuItem(
                "Editions",
                "Pages.Editions",
                "flaticon-app",
                "/app/admin/editions"
            ),
            new AppMenuItem(
                "GerarCredenciais",
                "Pages.GerarCredenciais",
                "flaticon-security",
                "ModalGerarCredenciais"
            ),
            new AppMenuItem(
                "Demandas",
                "Pages.Demandas",
                "flaticon-squares-4",
                "/app/main/demandas/kanban"
            ),
            new AppMenuItem(
                "Gestão de Mudanças",
                "Pages.Administration.ChangeManagement",
                "fas fa-exchange-alt",
                "",
                [
                    new AppMenuItem(
                        "Configurações",
                        "Pages.Administration.ChangeManagement.Configuration",
                        "flaticon-settings",
                        "/app/main/gestaoMudancas/config"
                    ),
                    new AppMenuItem(
                        "Aprovações",
                        "Pages.Administration.ChangeManagement.Approval",
                        "flaticon-like",
                        "/app/main/gestaoMudancas/aprovacoes"
                    ),
                    new AppMenuItem(
                        "Histórico de Aprovação",
                        "Pages.Administration.ChangeManagement.Approval",
                        "fas fa-history",
                        "/app/main/gestaoMudancas/historicoAprovacoes"
                    ),
                ]
            ),
            new AppMenuItem(
                "Gestão de Risco",
                "Pages.Administration.RiskManagement",
                "fas fa-ambulance",
                "",
                [
                    new AppMenuItem(
                        "Configurações",
                        "Pages.Administration.RiskManagement.Configuration",
                        "flaticon-settings",
                        "/app/main/risk-config"
                    ),
                ]
            ),
            new AppMenuItem(
                "Code Review",
                "Pages.Administration.ChangeManagement",
                "far fa-file-code",
                "",
                [
                    new AppMenuItem(
                        "Configurações",
                        "Pages.Administration.ChangeManagement.Configuration",
                        "fas fa-cog",
                        "/app/main/codeReview/config"
                    ),
                    new AppMenuItem(
                        "Avaliações",
                        "Pages.Administration.ChangeManagement.Approval",
                        "fas fa-thumbs-up",
                        "/app/main/codeReview/avaliacoes"
                    ),
                ]
            ),
            new AppMenuItem(
                "LiberacoesUsuarios",
                "Pages.LiberacoesUsuarios",
                "flaticon-map",
                "/app/main/liberacoes"
            ),

            new AppMenuItem(
                "AvaliacoesDeQualidades",
                "Pages.AvaliacoesDeQualidades",
                "flaticon-warning",
                "",
                [
                    new AppMenuItem(
                        "Configurações (Beta)",
                        "Pages.AvaliacoesDeQualidades.Configuration",
                        "fas fa-cog",
                        "/app/main/quality-config"
                    ),
                    new AppMenuItem(
                        "AvaliacoesDeQualidades",
                        "Pages.AvaliacoesDeQualidades",
                        "flaticon-warning",
                        "/app/main/avaliacoes-qualidade"
                    ),
                ]
            ),

            new AppMenuItem(
                "EnvironmentEvaluation",
                "Pages.AvaliacaoAmbiente",
                "fas fa-sitemap",
                "",
                [
                    new AppMenuItem(
                        "Agendamento",
                        "Pages.AvaliacaoAmbiente.Configuration",
                        "fas fa-clock",
                        "/app/main/avaliacaoAmbiente/agendamento"
                    ),
                    new AppMenuItem(
                        "Inventário",
                        "Pages.AvaliacaoAmbiente.Configuration",
                        "fas fa-folder-open",
                        "/app/main/avaliacaoAmbiente/inventario"
                    ),
                ]
            ),
            new AppMenuItem(
                "Deploy",
                "Pages.AvaliacaoAmbiente",
                "fas fa-cloud-upload-alt",
                "",
                [
                    new AppMenuItem(
                        "Configurações",
                        "Pages.AvaliacaoAmbiente.Configuration",
                        "fas fa-cog",
                        "/app/main/deploy"
                    ),
                ]
            ),
            new AppMenuItem(
                "Projeto",
                "Pages.Projects",
                "fas fa-project-diagram",
                "",
                [
                    new AppMenuItem(
                        "Configurações",
                        "Pages.Projects.Configuration",
                        "fas fa-cog",
                        "/app/main/projeto"
                    ),
                ]
            ),
            new AppMenuItem(
                "Suporte",
                "Pages.Administration.Support",
                "flaticon-support",
                "/app/admin/suporte"
            ),
            new AppMenuItem("Administration", "", "flaticon-interface-8", "", [
                new AppMenuItem(
                    "Notificações no Portal",
                    "Pages.Administration.Host.Settings",
                    "fas fa-bullhorn",
                    "/app/admin/system-notifications"
                ),
                new AppMenuItem(
                    "Roles",
                    "Pages.Administration.Roles",
                    "flaticon-suitcase",
                    "/app/admin/roles"
                ),
                new AppMenuItem(
                    "Documentation",
                    "Pages.Administration.Host.Documentation",
                    "flaticon-file-2",
                    "/app/admin/documentation"
                ),
                new AppMenuItem(
                    "Clarity",
                    "Pages.Administration.Host.Documentation",
                    "flaticon-line-graph",
                    environment.clarityUrl,
                    [],
                    true
                ),
                new AppMenuItem(
                    "Fornecedores",
                    "Pages.Administration.Fornecedores",
                    "flaticon-users",
                    "/app/admin/fornecedores/fornecedores"
                ),
                new AppMenuItem(
                    "Fornecedores",
                    "Pages.Administration.Fornecedores.Tenant",
                    "flaticon-users",
                    "/app/admin/fornecedores/view-fornecedores"
                ),
                new AppMenuItem(
                    "Ambientes",
                    "Pages.Administration.Ambientes",
                    "flaticon-map",
                    "/app/admin/ambientes/ambientes"
                ),
                new AppMenuItem(
                    "Cargos",
                    "Pages.Administration.Cargos",
                    "flaticon-suitcase",
                    "/app/admin/cargos/cargos"
                ),
                new AppMenuItem(
                    "Modulos",
                    "Pages.Administration.Modulos",
                    "flaticon-layers",
                    "/app/admin/modulos/modulos"
                ),
                new AppMenuItem(
                    "Users",
                    "Pages.Administration.Users",
                    "flaticon-users",
                    "/app/admin/users"
                ),
                new AppMenuItem(
                    "VersionControl",
                    "Pages.Administration.Host.Settings",
                    "fas fa-code-branch",
                    "/app/admin/system-version-control"
                ),
                new AppMenuItem(
                    "MinhasSenhas",
                    "Pages.Administration.MinhasSenhas",
                    "flaticon-lock",
                    "/app/admin/minhas-senhas/minhas-senhas"
                ),
                new AppMenuItem(
                    "OrganizationUnits",
                    "Pages.Administration.OrganizationUnits",
                    "flaticon-map",
                    "/app/admin/organization-units"
                ),
                /*new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages'),*/
                new AppMenuItem(
                    "AuditLogs",
                    "Pages.Administration.AuditLogs",
                    "flaticon-folder-1",
                    "/app/admin/auditLogs"
                ),
                new AppMenuItem(
                    "Maintenance",
                    "Pages.Administration.Host.Maintenance",
                    "flaticon-lock",
                    "/app/admin/maintenance"
                ),
                new AppMenuItem(
                    "Subscription",
                    "Pages.Administration.Tenant.SubscriptionManagement",
                    "flaticon-refresh",
                    "/app/admin/subscription-management"
                ),
                new AppMenuItem(
                    "Settings",
                    "Pages.Administration.Host.Settings",
                    "flaticon-settings",
                    "/app/admin/hostSettings"
                ),
                new AppMenuItem(
                    "Settings",
                    "Pages.Administration.Tenant.Settings",
                    "flaticon-settings",
                    "/app/admin/tenantSettings"
                ),
                new AppMenuItem(
                    "Integrations",
                    "Pages.Administration.Tenant.Settings",
                    "flaticon-interface-1",
                    "",
                    [
                        new AppMenuItem(
                            "ExternalAuthentication",
                            "Pages.Administration.Tenant.Settings",
                            "flaticon-users",
                            "/app/admin/external-intregrations"
                        ),
                        new AppMenuItem(
                            "UploadDashboardFile",
                            "Pages.Administration.Tenant.Settings",
                            "flaticon-upload",
                            "/app/admin/updload-dashboard-file"
                        ),
                        new AppMenuItem(
                            "ChangeManager",
                            "Pages.Administration.Tenant.Settings",
                            "fa-exchange-alt",
                            "/app/admin/change-manager"
                        ),
                        new AppMenuItem(
                            "Importação de STMS",
                            "Pages.Administration.Tenant.Settings",
                            "fa fa-truck",
                            "/app/admin/stms-import"
                        ),
                    ]
                ),
            ]),
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (
                subMenuItem.permissionName &&
                this._permissionCheckerService.isGranted(
                    subMenuItem.permissionName
                )
            ) {
                return true;
            } else if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName ===
                "Pages.Administration.Tenant.SubscriptionManagement" &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (
            menuItem.permissionName &&
            !this._permissionCheckerService.isGranted(menuItem.permissionName)
        ) {
            hideMenuItem = true;
        }

        if (
            this._appSessionService.tenant ||
            !abp.multiTenancy.ignoreFeatureCheckForHostUsers
        ) {
            if (
                menuItem.hasFeatureDependency() &&
                !menuItem.featureDependencySatisfied()
            ) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
