import { Component, EventEmitter, Injector, LOCALE_ID, OnInit, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AlteracaoDeStatusDaDemandaDto, AssignmentGroupDto, CreateOrEditGestaoMudancaDto, EnumNautrezaGmud, EnumRiscoGmud, GestaoMudancaRiscoViewDto, GestaoMudancaServiceProxy } from '@shared/service-proxies/service-proxies';
import moment from 'moment/moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-create-change-manager-modal',
  templateUrl: './create-change-manager-modal.component.html',
  styleUrls: ['./create-change-manager-modal.component.css']
})
export class CreateChangeManagerModalComponent extends AppComponentBase implements OnInit {
  @ViewChild('changeManagerModal', { static: true }) modal: ModalDirective;
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() manualChange: EventEmitter<any> = new EventEmitter<any>();

  changeManager: CreateOrEditGestaoMudancaDto = new CreateOrEditGestaoMudancaDto();
  alteracaoDeStatusDaDemandaDto: AlteracaoDeStatusDaDemandaDto = new AlteracaoDeStatusDaDemandaDto();
  saving: boolean = false;
  assignmentGroups: AssignmentGroupDto[] = [];
  configurationItems: AssignmentGroupDto[] = [];
  loading: boolean = false;
  validations = {
    risk: false
  };

  dataInicio: moment.Moment = moment();
  dataFim: moment.Moment = moment();


  get enumNautrezaGmud() {
    return EnumNautrezaGmud;
  }

  get enumRiscoGmud() {
    return EnumRiscoGmud;
  }

  get isInvalid() {
    return Object.keys(this.validations).map(key => this.validations[key]).some(item => !item)
  }

  constructor(injector: Injector, private _service: GestaoMudancaServiceProxy, private messageService: MessageService) {
    super(injector);
  }

  ngOnInit() {
    this.setValues();
  }

  async searchAssignmentGroup() {
    return new Promise<AssignmentGroupDto[]>((resolve, reject) =>
      this._service.changeManagerSearchAssignmentGroup().subscribe(result => {
        resolve(result)
      })
    )
  }

  async searchConfigurationItem() {
    return new Promise<AssignmentGroupDto[]>((resolve, reject) =>
      this._service.searchConfigurationItem().subscribe(result => {
        resolve(result)
      })
    )
  }

  setValues() {
    let changeManager = new CreateOrEditGestaoMudancaDto();
    changeManager.tipoMudanca = this.l('Normal');
    changeManager.categoria = this.l('Productivity');
    changeManager.acessoBase = this.l('Not');
    changeManager.justificativa = this.l('QAMetrikToolUsing');
    changeManager.gestaoMudancaRisco = new GestaoMudancaRiscoViewDto();
    changeManager.gestaoMudancaRisco.planoMudanca = this.l('QADevOpsProductionPublishing');
    changeManager.gestaoMudancaRisco.planoTeste = this.l('TechnicalAreaValidation');
    changeManager.planoRetorno = this.l('ReturnPlanValue');
    this.changeManager = changeManager;
  }

  close(): void {
    this.setValues();
    this.modal.hide();
    this.closed.emit();
  }

  async show(demandaId: string, alteracaoDeStatusDaDemandaDto: AlteracaoDeStatusDaDemandaDto) {
    this._service.getDadosDemanda(demandaId)
        .subscribe(res => {
          this.loading = true;
          this.assignmentGroups = null;//await this.searchAssignmentGroup();
          this.configurationItems = null; //await this.searchConfigurationItem();
          this.changeManager.demandaId = demandaId;
          this.changeManager.titulo = res.titulo;
          this.changeManager.descricao = res.descricao;
          this.alteracaoDeStatusDaDemandaDto = alteracaoDeStatusDaDemandaDto;
          this.loading = false;
          this.modal.show();
        })
  }

  save() {    
    if (this.changeManager.dataFim < this.changeManager.dataInicio)
      return this.showError();    

    this._service.createOrEdit(this.changeManager).subscribe(() => {
      this.setValues();
      this.modal.hide();
      this.saved.emit(this.alteracaoDeStatusDaDemandaDto);
    });
  }

  showError() {
    this.messageService.add({ 
        severity: 'warn', 
        summary: 'Atenção', 
        detail: 'Horário do fim da mudança deve ser maior que o horário do início da mundança, ajuste os minutos.', 
        life: 5000 
    });
  }

  manualChangeEvent() {
    this.manualChange.emit(this.alteracaoDeStatusDaDemandaDto);
    this.close();
  }
}

