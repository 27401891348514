import {
    Component,
    Injector,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    CronogramaAvaliacaoServiceProxy,
    EstruturasObjetosServiceProxy,
    SolicitaAvaliacaoAmbienteInput,
} from "@shared/service-proxies/service-proxies";
import { Table } from "primeng/table";
import { Paginator } from "primeng/paginator";
import { LazyLoadEvent } from "primeng/api";
import * as _ from "lodash";
import * as moment from "moment";
@Component({
    selector: "avaliacao-ambiente-agendamento",
    templateUrl: "./agendamento.component.html",
    styleUrls: ["./agendamento.component.less"],
    encapsulation: ViewEncapsulation.None,
})
export class AvaliacaoAmbienteAgendamentoComponent
    extends AppComponentBase
    implements OnInit
{
    @ViewChild("dataTable", { static: true }) dataTable: Table;
    @ViewChild("paginator", { static: true }) paginator: Paginator;

    checked = false;
    descricao: string;
    loading = false;
    loadingTable: boolean;

    ngOnInit(): void {
        this.loadingTable = true;
    }

    constructor(
        injector: Injector,
        private _agendamentoAvAmbienteService: CronogramaAvaliacaoServiceProxy,
        private _estruturasObjetosService: EstruturasObjetosServiceProxy
    ) {
        super(injector);
    }

    agendamento() {
        this.loading = true;
        this.loadingTable = true;
        let solicitaAvaliacaoAmbienteInput =
            new SolicitaAvaliacaoAmbienteInput();

        solicitaAvaliacaoAmbienteInput.descricaoAgendamento = this.descricao;
        solicitaAvaliacaoAmbienteInput.estruturaObjeto = true;
        solicitaAvaliacaoAmbienteInput.avaliacaoQualidade = this.checked;

        this._agendamentoAvAmbienteService
            .solicitaAvaliacaoAmbiente(solicitaAvaliacaoAmbienteInput)
            .subscribe(() => {
                this.loading = false;
                this.loadingTable = false;
                this.descricao = "";
                this.recarregar();
                this.notify.success(this.l("EvaluationScheduledSuccessfully"));
            });
    }

    getAgendamentos(event?: LazyLoadEvent) {
        this.loadingTable = true;
        if (event && this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }

        this.primengTableHelper.showLoadingIndicator();

        this._agendamentoAvAmbienteService
            .getListaAgendamentos(
                this.primengTableHelper.getSorting(this.dataTable),
                this.primengTableHelper.getSkipCount(this.paginator, event),
                this.primengTableHelper.getMaxResultCount(this.paginator, event)
            )
            .subscribe((result) => {
                this.loadingTable = false;
                this.primengTableHelper.totalRecordsCount = result.totalCount;
                this.primengTableHelper.records = result.items;
                this.primengTableHelper.hideLoadingIndicator();
            });
    }

    recarregar(event?: LazyLoadEvent) {
        this.getAgendamentos(event);
        this.checked = false;
    }

    getLocaleDateHour(date: moment.Moment) {
        const dateString = date ? date.toString() : "";
        return new Date(dateString).toLocaleString();
    }

    updateScheduling(idAgendamento: string) {
        this._agendamentoAvAmbienteService
            .atualizarAgendamento(idAgendamento)
            .subscribe((result) => {
                this.recarregar();
            });
    }

    synchronizeEnvironment(idAgendamento: string) {
        this._estruturasObjetosService
            .sumOfObjectStructureData(idAgendamento)
            .subscribe((result) => {
                this.recarregar();
            });
    }
}
