import {
    DemandasAnexosServiceProxy,
    VincularAnexoDemandaInput,
    EntityDtoOfGuid,
    GetDemandaAnexoForViewDto,
    UsuarioEnvolvidoDemandaSelect2,
    CreateOrEditAtividadeDto,
    ModulosServiceProxy,
    CreateOrEditDemandaUserUsuarioSapDto,
    RequestDemandaViewDto,
    Select2ItemDto,
    SapUsersServiceProxy,
    EnumTipoDemanda,
    EnumStatusDemanda,
    LogRequestAmbienteDto,
    StepLogDto,
    EnumLogStatus,
    Log,
    GestaoMudancaViewDto,
    GestaoMudancaRiscoViewDto,
    GestaoMudancaServiceProxy,
    CreateRequestDto,
    ChangeManagerSettingsEditDto,
    TenantSettingsEditDto,
    GestaoMudancaNativaViewDto,
    CodeReviewHistoricoDto,
    CodeReviewServiceProxy,
    StatusCodeReview,
    ProjectsServiceProxy,
    ProjetoDto,
    AlteracaoDeStatusDaDemandaDto,
    GetItensPercentualQualidade,
    TipoMudanca,
    GetGestaoMudancaNativaAnexoForViewDto,
    DemandaDto,
    RiscoMudanca,
    RiskManagementDto,
    RiskManagementConfigurationDto,
    RiskManagementConfigurationServiceProxy,
    GetDemandaForEditOutput
} from '@shared/service-proxies/service-proxies';
import {
    Component,
    ViewChild,
    Injector,
    Output,
    EventEmitter,
    OnInit,
    ViewEncapsulation,
    HostListener
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import {
    DemandasServiceProxy,
    CreateOrEditDemandaDto,
    CreateOrEditDemandaUserDto
} from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';
import { DemandaUserLookupTableModalComponent } from '../demanda-user-lookup-table-modal/demanda-user-lookup-table-modal.component';
import * as uuid from 'uuid';
import { FileUploader, FileUploaderOptions, FileItem } from 'ng2-file-upload';
import { AppConsts } from '@shared/AppConsts';
import { FileDownloadService } from '@shared/utils/file-download.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DemandaModuloLookupTableModalComponent } from '../demanda-modulo-look-up-table-modal/demanda-modulo-lookup-table-modal.component';
import { Table } from 'primeng/table';
import { HistoricoAvaliacaoCodigoComponent } from '../../../avaliacoesDeQualidade/historico-avaliacao-codigo/historico-avaliacao-codigo.component';
import { CodigoSapComponent } from '../../../avaliacoesDeQualidade/codigo-sap/codigo-sap.component';
import { NgModel } from '@angular/forms';
import { RiskStatusModalComponent } from '../risk-status-modal/risk-status-modal.component';
import { CreateRequestModalComponent } from '../create-request-modal/create-request-modal.component';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { CodeReviewCommentsModalComponent } from './code-reviewer-comments-modal/code-reviewer-comments-modal.component';
import { Router } from '@angular/router';
import { Paginator } from 'primeng/paginator';
import { AbpSessionService, IAjaxResponse, TokenService } from 'abp-ng2-module';
import { InformationDeployModalComponent } from '@app/main/gestaoMudancas/create-change-manager-native-modal/information-deploy/informationDeploy.component';
import { LazyLoadEvent } from 'primeng/api';
import { CreateChangeManagerNativeModalComponent } from '@app/main/gestaoMudancas/create-change-manager-native-modal/create-change-manager-native-modal.component';

interface EventItem {
    status?: string; 
    icon?: string;
}

@Component({
    selector: 'createOrEditDemandaModal',
    templateUrl: './create-or-edit-demanda-modal.component.html',
    styleUrls: ['./create-or-edit-demanda-modal.component.less'],
    encapsulation: ViewEncapsulation.None
})
export class CreateOrEditDemandaModalComponent extends AppComponentBase implements OnInit {

    demandaForEdit = {} as GetDemandaForEditOutput;

    @HostListener('dragover', ['$event']) onDragOver(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
      }
    
      @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
      }
    
      @HostListener('drop', ['$event']) onDrop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();
        
        const files = event.dataTransfer?.files;
        if (files && files.length > 0) {
          this.onFilesAdded(null,files);
        }
      }
      
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @ViewChild('riskStatusModal', { static: true }) riskStatusModal: RiskStatusModalComponent;
    @ViewChild('demandaUserLookupTableModal', { static: true }) demandaUserLookupTableModal: DemandaUserLookupTableModalComponent;
    @ViewChild('demandaModuloLookupTableModal', { static: true }) demandaModuloLookupTableModal: DemandaModuloLookupTableModalComponent;
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;
    @ViewChild('createRequestModal', { static: true }) createRequestModal: CreateRequestModalComponent;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('historicoAvaliacaoCodigo', { static: true }) historicoAvaliacaoCodigo: HistoricoAvaliacaoCodigoComponent;
    @ViewChild('codigoSap', { static: true }) codigoSap: CodigoSapComponent;
    @ViewChild('Demanda_Titulo', { static: true }) tituloField: NgModel;
    @ViewChild('Demanda_Referencias', { static: true }) referenciasField: NgModel;

    @ViewChild('codeReviewerCommentsModal', { static: true }) codeReviewerCommentsModal: CodeReviewCommentsModalComponent;
    @ViewChild('informationDeployModal', { static: true }) informationDeployModal: InformationDeployModalComponent;

    @ViewChild('createChangeManagerNative', { static: true }) createChangeManagerNative: CreateChangeManagerNativeModalComponent;

    _entityTypeFullName = 'QAMetrik.Demandas.Demanda';
    entityHistoryEnabled: boolean = true;

    columnSizeClassTipo = 'col-md-4'; // Tamanho padrão
    columnSizeClassModuloSap = 'col-md-8'; // Tamanho padrão

    active: boolean = false;
    saving: boolean = false;
    htmlEditorInput: any;
    demanda: CreateOrEditDemandaDto = new CreateOrEditDemandaDto();
    public uploader: FileUploader;
    private _uploaderOptions: FileUploaderOptions = {
        url: ''
    };
    anexosAdicionados: Array<GetDemandaAnexoForViewDto> = new Array();
    previsaoTerminoDev: Date;
    creationTime: Date;
    previsaoTerminoHomolog: Date;
    minDatePrevisaoTerminoHomolog: Date;
    userName = '';
    moduloSap = '';
    projeto = '';
    users: UsuarioEnvolvidoDemandaSelect2[];
    usersSelected: UsuarioEnvolvidoDemandaSelect2[];
    usersSelected2: UsuarioEnvolvidoDemandaSelect2[];

    usuariosJaExistentes: CreateOrEditDemandaUserDto[];

    textoNovaTarefa: string = '';
    atividades: CreateOrEditAtividadeDto[] = new Array();
    listaDeReferencias: string[] = new Array();
    tabShow = '0';
    anexosVinculados: Array<string> = new Array();
    add: boolean = true;
    demandaId: string = '';
    guidArquivoAnexo: string = '';
    entityGuid: EntityDtoOfGuid = new EntityDtoOfGuid();
    requestDemandaViewDto: RequestDemandaViewDto[] = new Array();
    minDatePrevisaoTerminoDev: Date = new Date();

    usuariosSapsDisponiveis = new Array<Select2ItemDto>();
    usuariosSapBuffer = new Array<Select2ItemDto>();
    bufferSize: number = 20;
    numberOfItemsFromEndBeforeFetchingMore: number = 20;
    podeAlterarResponsavel: boolean = false;
    podeSalvar: boolean = false;
    avaliacaoId: string = '';
    requestPanelContent: number = 0;

    statusGmud: EventItem[] = [];
    statusAprovador: EventItem[] = [];

    logRequest: Array<any> = [];
    lastRequestId: string = "";
    originalLogRequest: LogRequestAmbienteDto[] = [];
    downloadingItens: string[] = [];
    gestaoDeRisco = null;
    riscoGeral = false;
    gestaoMudanca: GestaoMudancaViewDto = new GestaoMudancaViewDto();
    gestaoMudancaNativa: GestaoMudancaNativaViewDto = new GestaoMudancaNativaViewDto();
    updateChange: boolean = false;
    loading = {
        deploy: false,
        demandStatus: false
    };

    currentsObjects: string[] = [];
    settings: TenantSettingsEditDto = new TenantSettingsEditDto();
    mudancaAprovada: boolean = false;

    codeReviewHistoricoDto: CodeReviewHistoricoDto[] = [];
    percentualQualidade: GetItensPercentualQualidade[] = [];
    projetostList: ProjetoDto[] = [];
    riskManagement: RiskManagementDto[] = [];
    riskManagementConfirmed: boolean = false;
    riskManagementConfiguration: RiskManagementConfigurationDto;

    getHistoricoCodeReview() {
        this._codeReviewServiceProxy.getHistoricoCodeReview(this.demandaId).subscribe(res => {
            this.codeReviewHistoricoDto = res;
        })
    }

    get changeManagerSettings(): ChangeManagerSettingsEditDto {
        return this.settings.changeManager || new ChangeManagerSettingsEditDto();
    }

    getavaliacaoQualidadeTabelaColuna(n: number = 1): string {
        const avaliacaoqualidadeTabelaColuna: number = 4.16;
        return `width: ${avaliacaoqualidadeTabelaColuna * n}%;`;
    }

    get status() {
        return this.demanda.status || "";
    }

    get disabledDeployButton() {
        const hasRequest = this.requestDemandaViewDto && this.requestDemandaViewDto.length > 0;
        if (!hasRequest) {
            return true;
        }

        return false;
    }

    get showDeployButton() {
        return this.demanda.status == EnumStatusDemanda.Producao && this.isGranted("Pages.Demandas.ProductiveDeploy") && this.appSession.isAdminUser;
    }

    get gestaoMudancaRisco(): GestaoMudancaRiscoViewDto {
        return this.gestaoMudanca.gestaoMudancaRisco || new GestaoMudancaRiscoViewDto();
    }

    getLocaleDate(date: moment.Moment) {
        const dateString = date ? date.toString() : "";
        return new Date(dateString).toLocaleDateString();
    }

    getLocaleDateHour(date: moment.Moment) {
        const dateString = date ? date.toString() : "";
        return new Date(dateString).toLocaleString();
    }

    get hasLogRequest() {
        return !!this.logRequest && this.logRequest.length > 0;
    }

    get enumStatusDemanda() {
        return EnumStatusDemanda;
    }

    get enumTipoMudanca() {
        return TipoMudanca;
      }

    get statusLog(): (log) => string[] {
        return (ambiente) => {
            const found = ambiente.logs.find(lg => lg.status != EnumLogStatus.LogStmsSuccess && lg.status);
            const foundWarn = ambiente.logs.find(lg => lg.status != EnumLogStatus.LogStmsSuccess && lg.status != EnumLogStatus.LogStmsError && lg.status);
            if (foundWarn) return [this.l("LogStmsWarning"), "warning"];
            if (found) return [this.l(found.status), "danger"];
            if (!found && ambiente.logs.length > 0) return [this.l("LogStmsSuccess"), "success"];
            if (!found) return ["(-)", "body"];
        }
    }

    set _logRequest(value: LogRequestAmbienteDto[]) {
        this.originalLogRequest = value;
        this.logRequest = value.map((item, index) => {
            return {
                key: index.toString(),
                label: [`${item.systemId} - ${item.descricao}`, ...this.statusLog(item)],
                children: item.logs.map((lg: Log, i: number) => {
                    const success = lg.status == EnumLogStatus.LogStmsSuccess;
                    const warning = lg.status == EnumLogStatus.LogStmsWarning;
                    const statusMsg = this.l(lg.status);
                    let statusType;
                    if (success) {
                        statusType = "success";
                    } else if (warning) {
                        statusType = "warning";
                    } else {
                        statusType = "danger";
                    }
                    return {
                        key: `${index.toString()}-${i}`,
                        label: [lg.descricao, statusMsg, statusType, lg.timestamp.toLocaleString(), true]
                    }
                })
            }
        })
        this.requestPanelContent = 1;
    }

    get enumTipoDemanda() {
        return EnumTipoDemanda;
    }

    get defaultSize(): (length: number) => number {
        return (length: number) => {
            return length <= 4 ? length : 4
        }
    }

    get hasCreateRequest(): boolean {
        return this.demanda.status == EnumStatusDemanda.Desenvolvimento || this.demanda.status == EnumStatusDemanda.Homologacao
    }

    get enumTipoDemandaKeys() {
        return Object.keys(EnumTipoDemanda)
    }

    get temRisco(): boolean {
        return true
    }

    get riscoGeralLabel() {
        return !this.riscoGeral ? "NenhumRisco" : "AltoRiscoDeTransporte"
    }

    getRiskStatus(length: number, isJustified: boolean) {
        if (length == 0) {
            return {
                icon: "ok-cor fa-check-circle",
                label: this.l("NenhumRiscoEncontrado")
            }
        }
        if (isJustified) {
            return {
                icon: "justificado-cor fa-check-circle",
                label: this.l("RequestDependentesJustificado")
            }
        }
        return {
            icon: "fa-exclamation-triangle risco-cor",
            label: this.l("RequestDependentes")
        }
    }

    getRisckOptions(options: Array<any>) {
        return options.filter(item => !item.hidden)
    }

    get gestaoMudancaStatus() {
        return "is-valid"
    }

    constructor(
        private injector: Injector,
        private _demandasServiceProxy: DemandasServiceProxy,
        private _demandaAnexoServiceProxy: DemandasAnexosServiceProxy,
        private _fileDownloadService: FileDownloadService,
        private _usuariosSap: SapUsersServiceProxy,
        private _sessionService: AbpSessionService,
        private _tokenService: TokenService,
        private _moduleService: ModulosServiceProxy,
        private _gestaoMudancaService: GestaoMudancaServiceProxy,
        private _appSessionService: AppSessionService,
        private _codeReviewServiceProxy: CodeReviewServiceProxy,
        private _projectsServiceProxy: ProjectsServiceProxy,
        private _riskManagementConfigurationServices: RiskManagementConfigurationServiceProxy,
        private _route: Router
    ) {
        super(injector);
    }
    
    ngOnInit(): void {
        this.setStatusItems();
        this.getProjects();
        this.getRiskManagementConfiguration();
    }

    setStatusItems() { 
        this.statusGmud = [
            { status: 'Aprovado', icon: 'fa fa-solid fa-check' },
            { status: 'Em análise', icon: 'fa fa-solid fa-hourglass-start' },
            { status: 'Reprovado', icon: 'fa fa-solid fa-times' },
        ];

        this.statusAprovador = [
            { status: 'Aprovado', icon: 'fa fa-solid fa-check' },
            { status: 'Aprovado automaticamente', icon: 'fa fa-solid fa-user-check' },
            { status: 'Aguardando aprovação', icon: 'fa fa-solid fa-hourglass-start' },
            { status: 'Reprovado', icon: 'fa fa-solid fa-times' }
        ];
    }

    setColorsBackgroundStatus(status: string) {
        switch (status) {
            case 'Aprovado':
                return 'background-color: #299F00;';
            case 'Aprovado automaticamente':
                return 'background-color: #299F00;';
            case 'Aguardando aprovação':
                return 'background-color: #ffa500;';
            case 'Em análise':
                return 'background-color: #ffa500;';
            case 'Reprovado':
                return 'background-color: #FF6464;';
        }
    }

    importStms(requestId: string) {
        this._demandasServiceProxy.gerarImportacaoStms(requestId, this.demandaId)
            .pipe(finalize(() => this.getDemanda()))
            .subscribe();
    }

    getDownloading(key: string) {
        return this.downloadingItens.find(item => item == key);
    }

    setDownloading(value: string) {
        this.downloadingItens.push(value);
    }

    removeDownloading(value: string) {
        const index = this.downloadingItens.indexOf(this.getDownloading(value));
        this.downloadingItens.splice(index, 1)
    }

    refreshLog() {
        this.requestPanelContent = -1;
        this._demandasServiceProxy.refreshLogForRequest(this.lastRequestId).subscribe((result: LogRequestAmbienteDto[]) => {
            this._logRequest = result;
        })
    }

    openLogForRequest(requestId: string | undefined) {
        if (!requestId) return;
        this.requestPanelContent = -1;
        this.lastRequestId = requestId || this.lastRequestId;
        this._demandasServiceProxy.getAllLogsForRequestAmbiente(this.lastRequestId)
            .subscribe((result: LogRequestAmbienteDto[]) => {
                this._logRequest = result;
            })
        return;
    }

    downloadLogRequest(id: string) {
        if (this.getDownloading(id)) {
            return;
        }
        const keys: string[] = id.split("-");
        const log: LogRequestAmbienteDto = this.originalLogRequest[keys[0]];
        const childLog: Log = log.logs[keys[1]];

        this.setDownloading(id);
        this._demandasServiceProxy.getStepLogRequest(this.lastRequestId, log.systemId, childLog.type, childLog.qtdLines)
            .pipe(finalize(() => this.removeDownloading(id)))
            .subscribe((stepLog: StepLogDto) => {
                let logToDownload = this.generateStepLog(stepLog);
                let fileLink = document.createElement('a')
                fileLink.setAttribute(
                    'href',
                    `data:text/plain;base64,${btoa(logToDownload)}`
                )
                fileLink.setAttribute('download', `${stepLog.stepLogId} - ${stepLog.system} - ${stepLog.type}`)
                document.body.appendChild(fileLink)
                fileLink.click()
                fileLink.remove()
            })

    }

    generateStepLog(stepLog: StepLogDto) {
        let logFile: string[] = [];
        for (const line of stepLog.logList) {
            let level = parseInt(line.level.trim());
            let ident = this.generateIdent(level)
            logFile.push(ident + line.content)
        }
        return logFile.join("\n");
    }

    generateIdent(level: number): string {
        let str = "";
        for (let i = 1; i <= level; i++) {
            str = str.concat("  ");
        }
        return str;
    }

    show(demandaId?: string, tabId?: string): void {
        this.buscarTodosUsuariosSap();
        this.previsaoTerminoDev = null;
        this.creationTime = null;
        this.previsaoTerminoHomolog = null;
        this.atividades = new Array();
        this.tabShow = tabId || '0';
        this.usersSelected = new Array();
        this.usersSelected2 = new Array();
        this.listaDeReferencias = new Array();
        this.buscarUsuarios(this._sessionService.tenantId, !demandaId);
        this.setModuloIdNull();
        this.setProjetoIdNull();
        this.getRiskManagementConfiguration();

        this.gestaoDeRisco = null;
        this.percentualQualidade = [];

        this.demandaId = demandaId;
        if (!demandaId) {
            this.podeAlterarResponsavel = true;
            this.usuariosJaExistentes = [];
            this.add = true;
            this.demanda = new CreateOrEditDemandaDto();
            this.moduloSap = '';
            this.projeto = '';
            this.active = true;
            this.previsaoTerminoDev = new Date();
            this.creationTime = new Date();
            this.alteradoDataDeDev(this.previsaoTerminoDev);
            this.primengTableHelper.records = null;
            this.demanda.usuarioResponsavelId = this.appSession.userId;
            this.userName = this.appSession.user.name + ' ' + this.appSession.user.surname;
            this.podeSalvar = true;
            this.setColumnSizeStart();
            this.modal.show();

        } else {
            this.add = false;
            this.getDemanda();
            this.getHistoricoCodeReview();
        }
        if (demandaId && this.tabShow == "3") this.getAvaliacaoQualidade();
    }

    getObjects(requestId, taskId) {
        this.requestPanelContent = -1;
        this._demandasServiceProxy.getObjetos(requestId, taskId)
            .subscribe(res => {
                this.requestPanelContent = 2;
                this.currentsObjects = res
            })
    }

    getObjectsForTask(requestId, item, taskId) {
        if (item.listaDeObjetos || item.listaObjetos) {
            item.listaDeObjetos = undefined;
            item.listaObjetos = undefined;
            return;
        }
        this._demandasServiceProxy.getObjetos(requestId, taskId)
            .subscribe(res => {
                item.listaDeObjetos = res
                item.listaObjetos = res
            })
    }

    getAvaliacaoQualidade() {
        this.tabShow = '3';
        this.primengTableHelper.isLoading = true;
        this._demandasServiceProxy
            .getDemandAssessment(this.demandaId)
            .pipe(finalize(() => this.primengTableHelper.isLoading = false))
            .subscribe(res => this.primengTableHelper.records = res);

    }

    getPercentualQualidade() {
        this.tabShow = '4';
        this.primengTableHelper.isLoading = true;
        this._demandasServiceProxy
            .getDemandCodeCoverage(this.demandaId)
            .pipe(finalize(() => this.primengTableHelper.isLoading = false))
            .subscribe(res => this.percentualQualidade = res);


    }
    defaultSapUser(usuarioDevops: UsuarioEnvolvidoDemandaSelect2, event) {
        event.preventDefault();
        const user = this.usuariosSapBuffer.find(item => item.value == usuarioDevops.usuarioSapPadrao);
        usuarioDevops.usuariosSAPSelecionados = usuarioDevops.usuariosSAPSelecionados || [];
        const found = usuarioDevops.usuariosSAPSelecionados.find(item => item.value == user.value);
        if (found) return;
        usuarioDevops.usuariosSAPSelecionados = [...usuarioDevops.usuariosSAPSelecionados, user];
    }

    getDemanda() {
        this._demandasServiceProxy.getDemandaForEdit(this.demandaId)
            .subscribe(result => {
                this.avaliacaoId = result.avaliacaoId;
                this.demandaForEdit = result;
                this.demanda = result.demanda;

                if (this.appSession.isAdminUser || this.demanda.usuarioResponsavelId == this.appSession.userId)
                    this.podeAlterarResponsavel = true; //this.appSession.user.userName.includes('admin.') || this.demanda.usuarioResponsavelId == this.appSession.userId;                  

                this.requestDemandaViewDto = result.requestsView;
                if (this.demanda.previsaoTerminoDev) {
                    this.previsaoTerminoDev = this.demanda.previsaoTerminoDev.toDate();
                }
                if (this.demanda.previsaoTerminoHomolog) {
                    this.previsaoTerminoHomolog = this.demanda.previsaoTerminoHomolog.toDate();
                }

                if (this.demanda.creationTime) {
                    this.creationTime = this.demanda.creationTime.toDate();
                }

                this.alteradoDataDeDev(this.previsaoTerminoDev);
                this.userName = result.userName;
                this.active = true;
                this.atividades = this.demanda.atividades;
                this.listaDeReferencias = this.demanda.listaDeReferencias;

                this._demandasServiceProxy
                    .getResponsaveisDaDemanda(this.demanda.id)
                    .subscribe(x => {
                        this.usersSelected = x;
                        this.usersSelected2 = x;


                    });
                if (this.demanda.estimativa == 0)
                    this.demanda.estimativa = null;

                this.entityGuid.id = this.demandaId;
                this._demandaAnexoServiceProxy
                    .buscarTodosOsAnexosDaDemanda(this.entityGuid)
                    .subscribe(x => {
                        this.anexosAdicionados = x.items;
                    });

                if (result.demanda.moduloId) {
                    this._moduleService.getModuloForView(result.demanda.moduloId).subscribe(res => {
                        this.moduloSap = res.modulo.nome;
                    });
                }

                if (result.demanda.projetoId) {
                    this._projectsServiceProxy.getProject(result.demanda.projetoId).subscribe(res => {
                        this.projetostList.push(res);
                        this.projeto = res.nome;
                    })
                }

                this.usuariosJaExistentes = result.demanda.usuariosParticipantesDaRequest;

                this.podeSalvar = this.pode_salvar();
                this.modal.show();

                this.gestaoMudanca = result.gestaoMudanca || new GestaoMudancaViewDto();
                this.gestaoMudancaNativa = result.gestaoMudancaNativa || new GestaoMudancaNativaViewDto();

                if (result.demanda.tipo == this.enumTipoDemanda.Projeto) {
                    this.setColumnSizeProjects();
                    this.getProjects();
                }
                else
                    this.setColumnSizeStart();

                this.riskManagement = result.riskManagement;
                this.riskManagementConfirmed = this.riskManagement.some(item => !item.confirmed) && 
                                               this.riskManagementConfiguration != undefined  &&
                                               this.riskManagementConfiguration.activeRisk ? true : false;
            })
    }

    private setColumnSizeStart() {
        this.columnSizeClassTipo = `col-md-4`;
        this.columnSizeClassModuloSap = `col-md-8`;
    }

    private setColumnSizeProjects() {
        this.columnSizeClassTipo = `col-md-3`;
        this.columnSizeClassModuloSap = `col-md-6`;
    }

    riskSaved() {
        this.getDemanda();
        this.modalSave.emit();
    }


    pode_salvar(): boolean {
        // 0 - Se a demanda eh nova, permite salvar
        if (!this.demandaId) return true;
        
        if (this.appSession.isAdminUser) {
            return true;
        } else {
            if (this.demanda.status == EnumStatusDemanda.Producao || this.demanda.status == EnumStatusDemanda.Concluido) return false;
            if (this.demanda.usuarioResponsavelId == this.appSession.userId) return true;
        }

        // 1 - Qualquer membro da demanda pode alterar o responsavel e qualquer campo da demanda
        // 2 - Sendo QUE apenas os membros da demanda podem alterar os campos da demanda
        // 3 - se EU não estou na demanda, por mais que pesquisei ela ... EU não posso conseguir alterar e salvar nada dentro dela ... pq eu não estou envolvida nela
        if (this.eh_membro_demanda()) return true;
        // CENÁRIO IMPOSSIVEL
        // 4 - quando a demanda não possui nenhum responsável e nenhum membro, neste caso qualquer usuário poderá adicionar responsavel e editar os dados da demanda.
        // 5 - quando a demanda encontra-se com status de Desenvolvimento para frente no kanban, não deve permitir alterar todos os usuários envolvidos. 
        return false;
    }

    eh_membro_demanda(): boolean {
        this.usersSelected2.forEach(el => {
            if (this.appSession.userId == parseInt(el.value)) return true;
        });
        return false;
    }

    validDemand() {
        if (this.demanda.moduloId == null) {
            this.notify.warn(this.l("ModuleMandatory"));
            return false;
        }
        if (this.tituloField.invalid) {
            this.notify.warn(this.l("TitleMandatory"));
            return false;
        }
        if (this.referenciasField.invalid) {
            this.notify.warn(this.l("ReferencyRequired"));
            return false;
        }

        return true;
    }

    save(): void {
        this.saving = true;
        if (!this.validDemand()) {
            this.saving = false;
            return;
        }

        if (this.previsaoTerminoDev) {
            if (!this.demanda.previsaoTerminoDev) {
                this.demanda.previsaoTerminoDev = moment(
                    this.previsaoTerminoDev
                ).startOf('day');
            } else {
                this.demanda.previsaoTerminoDev = moment(
                    this.previsaoTerminoDev
                );
            }
        } else {
            this.demanda.previsaoTerminoDev = null;
        }
        if (this.previsaoTerminoHomolog) {
            if (!this.demanda.previsaoTerminoHomolog) {
                this.demanda.previsaoTerminoHomolog = moment(

                    this.previsaoTerminoHomolog
                ).startOf('day');
            } else {
                this.demanda.previsaoTerminoHomolog = moment(
                    this.previsaoTerminoHomolog
                );
            }
        } else {
            this.demanda.previsaoTerminoHomolog = null;
        }
        this.demanda.responsaveis = [];
        this.demanda.usuariosParticipantesDaRequest = [];
        this.usersSelected2.forEach(el => {
            let dto = new CreateOrEditDemandaUserDto();

            dto.demandaId = this.demanda.id;
            dto.id = uuid.v4();
            dto.userId = parseInt(el.value);
            this.demanda.responsaveis.push(dto);
            if (el.usuariosSAPSelecionados) {
                el.usuariosSAPSelecionados.forEach(sap => {
                    let dtoParticipante = new CreateOrEditDemandaUserUsuarioSapDto();
                    dtoParticipante.id = uuid.v4();
                    dtoParticipante.demandaId = this.demanda.id;
                    dtoParticipante.userId = parseInt(el.value);
                    dtoParticipante.usuarioSapId = sap.value;
                    this.demanda.usuariosParticipantesDaRequest.push(dtoParticipante);
                });
            }
        });
        if (this.demanda.usuariosParticipantesDaRequest == null || this.demanda.usuariosParticipantesDaRequest.length == 0) {
            this.notify.warn(this.l("UsuariosSAPInvalido"));
            this.saving = false;
            return;
        }
        this.demanda.referencias = this.listaDeReferencias.join('|');
        this.demanda.atividades = this.atividades;

        this._demandasServiceProxy
            .createOrEdit(this.demanda)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
            });
    }

    openSelectUserModal() {
        this.demandaUserLookupTableModal.id = this.demanda.usuarioResponsavelId;
        this.demandaUserLookupTableModal.displayName = this.userName;
        this.demandaUserLookupTableModal.show();
    }

    updateChangeManagementStatus() {
        this.updateChange = true;
        this._gestaoMudancaService.updateDemandChangeManagementStatus(this.demandaId).pipe(finalize(() => this.updateChange = false)).subscribe(() => this.getDemanda())
    }

    setUsuarioResponsavelIdNull() {
        // this.demanda.usuarioResponsavelId = null;
        // this.userName = '';
    }

    getNewUsuarioResponsavelId() {
        this.demanda.usuarioResponsavelId = this.demandaUserLookupTableModal.id;
        this.userName = this.demandaUserLookupTableModal.displayName;

        this.adicionarUsuarioResponsavelNoEnvolvido(this.demanda.usuarioResponsavelId.toString());
    }

    adicionarUsuarioResponsavelNoEnvolvido(id: string) {
        let dtoNovoEnvolvido = this.users.find(x => x.value === id);
        if (!this.usersSelected.find(x => x.value === id)) {
            this.usersSelected.push(dtoNovoEnvolvido);
            this.usersSelected = [].concat(this.usersSelected);
        }
        if (!this.usersSelected2.find(x => x.value === id)) {
            this.usersSelected2.push(dtoNovoEnvolvido);
            this.usersSelected2 = [].concat(this.usersSelected2);
        }

    }

    close(): void {
        this.active = false;
        this.modal.hide();
        this._route.navigate(['/app/main/demandas/kanban/'])
    }

    buscarUsuarios(tenantId: number, addUsuarioSessaoEnvolvido: boolean) {
        this._demandasServiceProxy
            .getDadosCompletosForSelect2(tenantId)
            .subscribe(x => {

                this.users = x;

                if (addUsuarioSessaoEnvolvido) {
                    this.adicionarUsuarioResponsavelNoEnvolvido(this.appSession.userId.toString());
                }
            });
    }

    onFilesAdded(event: any, fileList:FileList | null) {

        const files: File[] = [];

        if (fileList != null) {
            for (let i = 0; i < fileList.length; i++) {
                files.push(fileList[i]);
            }
        }

        if(event != null){
            const input = event.target as HTMLInputElement;
            if (input.files) {
                for (let i = 0; i < input.files.length; i++) {
                    files.push(input.files[i]);
                }
            }
        }

        this.uploader = new FileUploader({
            url: AppConsts.remoteServiceBaseUrl + '/File/UploadAttachment'
        });
        this.uploader.clearQueue();

        if (files.length > 0) {
            this.uploader.addToQueue(files);
        }

        this._uploaderOptions.autoUpload = false;
        this._uploaderOptions.authToken = 'Bearer ' + this._tokenService.getToken();
        this._uploaderOptions.removeAfterUpload = true;
        this._uploaderOptions.url = AppConsts.remoteServiceBaseUrl + '/File/UploadAttachment'

        files.forEach(file => {
            this.uploader.onAfterAddingFile = file => {
                file.withCredentials = false;
            };

            this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
                this.guidArquivoAnexo = uuid.v4();
                form.append('FileType', fileItem.file.type);
                form.append('FileName', fileItem.file.name);
                form.append('FileToken', this.guidArquivoAnexo);
            };
            this.uploader.setOptions(this._uploaderOptions);

            this.uploader.onSuccessItem = (item, response, status) => {
                const resp = <IAjaxResponse>JSON.parse(response);
                if (resp.success) {
                    let inputVincularAnexo: VincularAnexoDemandaInput = new VincularAnexoDemandaInput();
                    inputVincularAnexo.anexoId = this.guidArquivoAnexo;
                    inputVincularAnexo.demandaId = this.demandaId;
                    inputVincularAnexo.fileType = item.file.type;
                    inputVincularAnexo.nomeAnexo = item.file.name;
                    this.primengTableHelper.isLoading = true;
                    this._demandasServiceProxy
                        .adicionarAnexoADemanda(inputVincularAnexo)
                        .pipe(finalize(() => this.primengTableHelper.isLoading = false))
                        .subscribe(() => {
                            this.recarregarAnexos();
                        });
                } else {
                    this.message.error(resp.error.message);
                }
            };

            this.primengTableHelper.isLoading = true;
            this.uploader.uploadAll();
        });
    }

    NovaAtividade() {
        if (this.textoNovaTarefa.trim() === '' || this.textoNovaTarefa.length <= 2) {
            return null;
        }

        let atividade = new CreateOrEditAtividadeDto();
        atividade.id = uuid.v4();
        atividade.nome = this.textoNovaTarefa;
        atividade.demandaId = this.demanda.id;
        this.textoNovaTarefa = '';
        document.getElementById('inputTextoNovaTarefa').focus();
        this.atividades.push(atividade);
    }

    RemoverAtividade(id: string) {
        this.atividades = this.atividades.filter(x => x.id !== id);
    }

    ResolverAtividade(id: string) {
        this.atividades.forEach(at => {
            if (at.id === id) {
                at.concluida = !at.concluida;
            }
        });
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }
    }

    downloadAnexo(anexo: GetDemandaAnexoForViewDto) {
        this._fileDownloadService.downloadBinaryFile(
            anexo.fileStorageId,
            anexo.fileType,
            anexo.nomeAnexo,
            this.appSession.tenantId
        );
    }

    removerAnexo(anexo: GetDemandaAnexoForViewDto) {
        this.message.confirm('', this.l('RemoverAnexo'), isConfirmed => {
            if (isConfirmed) {
                this._demandaAnexoServiceProxy
                    .delete(anexo.id)
                    .subscribe(() => {
                        this.recarregarAnexos();
                        this.notify.success(this.l('SuccessfullyDeleted'));
                    });
            }
        });
    }

    recarregarAnexos() {
        this._demandaAnexoServiceProxy
            .buscarTodosOsAnexosDaDemanda(this.entityGuid)
            .subscribe(res => {
                this.anexosAdicionados = res.items;
            });
    }

    openSelectModuleModal() {
        this.demandaModuloLookupTableModal.show();
    }

    getNewModuleId() {
        this.demanda.moduloId = this.demandaModuloLookupTableModal.id || null;
        this.moduloSap = this.demandaModuloLookupTableModal.displayName || null;
    }

    setModuloIdNull() {
        this.demanda.moduloId = null;
        this.moduloSap = '';
    }
    setProjetoIdNull() {
        this.demanda.projetoId = '';
        this.projeto = '';
    }
    clear(item: Select2ItemDto) {
        this.usersSelected = [].concat(this.usersSelected.filter(x => x.value !== item.value));
        this.usersSelected2 = [].concat(this.usersSelected2.filter(x => x.value !== item.value));
    }

    exibirExcluirUsuario(item: Select2ItemDto): boolean {

        let usuarioSelecionado = this.usuariosJaExistentes.find(x => x.userId.toString() == item.value);
        if (this.demanda.status == EnumStatusDemanda.Backlog || usuarioSelecionado == undefined)
            return true;
        return false;
    }

    onRemove($event) {

        let k = $event.value;
        if (!this.exibirExcluirUsuario(k)) {
            this.usersSelected.push(k);
            this.usersSelected = [].concat(this.usersSelected);
        }
    }


    clearSap(item: Select2ItemDto, user: UsuarioEnvolvidoDemandaSelect2) {
        user.usuariosSAPSelecionados = user.usuariosSAPSelecionados.filter(el => el.value != item.value);
        return;
    }

    alteradoDataDeDev(ev: Date) {

        if (this.previsaoTerminoHomolog < ev) {
            this.previsaoTerminoHomolog = null;
        }
        let value = ev.valueOf();
        value += 86400000 * 1;
        // Alterado para permitir inicio = fim
        this.minDatePrevisaoTerminoHomolog = ev; //new Date(value);
    }

    buscarTodosUsuariosSap() {
        this._usuariosSap.getAllSapUsers().subscribe(result => {
            this.usuariosSapsDisponiveis = result;
            this.usuariosSapBuffer = this.usuariosSapsDisponiveis;//.slice(0, this.bufferSize);
        });
    }

    onScroll({ end }) {
        if (this.usuariosSapsDisponiveis.length <= this.usuariosSapBuffer.length) {
            return;
        }

        if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.usuariosSapBuffer.length) {
            this.fetchMore();
        }
    }

    fetchMore() {
        const len = this.usuariosSapBuffer.length;
        const more = this.usuariosSapsDisponiveis.slice(len, this.bufferSize + len);
        this.usuariosSapBuffer = this.usuariosSapBuffer.concat(more);
    }

    changeUsersSelected(ev: UsuarioEnvolvidoDemandaSelect2[]) {
        // Não permite usuário já incluído

        ev.forEach(element => {

            if (!this.usersSelected2.find(x => x.value === element.value)) {
                // if(this.exibirExcluirUsuario(element))
                this.usersSelected2.push(element);

            }
            else {
                // this.notify.warn("teste")

            }
        });
    }

    openRequestModal(event) {
        this.createRequestModal.showToCreateRequest(this.demandaId, this.demanda.status);
    }

    createRequest(event) {
        const dto = new CreateRequestDto()
        dto.demandaId = event.demandaId
        dto.listaAmbientes = event.environmentList;
        this._demandasServiceProxy.createRequest(dto)
            .subscribe(x => {
                this.notify.success(this.l('SuccessCreateRequest'));
                this.show(this.demandaId, this.tabShow);
            });
    }

    ProductiveDeploy() {
        this.loading.deploy = true;
        /*
        this._demandasServiceProxy.integrationToProductiveDeploy(this.demanda.demandaSapId)
            .pipe(finalize(() => {
                this.getDemanda();
                this.loading.deploy = false;
                location.reload();
            }))
            .subscribe(res => {
                if (!res) {
                    let demand: AlteracaoDeStatusDaDemandaDto = new AlteracaoDeStatusDaDemandaDto();
                    demand.demandaId = this.demanda.id;
                    let res = "A próxima janela de transporte é um período de exceção. O transporte será realizado na próxima janela disponível. Você deseja continuar?";
                    this.informationDeployModal.show(res, null, demand, true, null);
                }
            });
        */

        this._demandasServiceProxy.integrationToProductiveDeploy(this.demanda.demandaSapId)
        .subscribe(res => {
            if (!res) {
                let demand: AlteracaoDeStatusDaDemandaDto = new AlteracaoDeStatusDaDemandaDto();
                demand.demandaId = this.demanda.id;
                this.loading.deploy = false;
                let res = "A data atual se encontra em um período de 'freezing'. Será necessário a aprovação do Gestor. Você deseja continuar?";
                this.informationDeployModal.show(res, null, demand, true, null);
            } else {
                this.getDemanda();
                this.loading.deploy = false;
                location.reload();
            }
        });
    }

    updateDemandStatus() {
        this.loading.demandStatus = true
        this._demandasServiceProxy.demandConclusion(this.demandaId)
            .pipe(finalize(() => this.loading.demandStatus = false))
            .subscribe(() => {
                this.close();
                this.modalSave.emit();
            })
    }

    //Verifica qual GMUD está ativo
    changeManagerNativeTabView(): boolean {
        if (this._appSessionService.tenant.tennantSettings.changeManager.isEnabledNative) {
            return true;
        }
        return false;
    }

    changeManagerTabView(): boolean {
        if (this._appSessionService.tenant.tennantSettings.changeManager.isEnabled) {
            return true;
        }
        return false;
    }

    inadequate(codeReviewDemandaId: string, avaliador: string, dataAvaliacao: moment.Moment): void {
        this.codeReviewerCommentsModal.show(codeReviewDemandaId, avaliador, dataAvaliacao);
    }

    get enumStatusCodeReview(): (key: string) => StatusCodeReview {
        return (key: string) => StatusCodeReview[key];
    }

    getLocaleDateHourCodeReview(dataAvaliacao: moment.Moment) {
        const dateString = dataAvaliacao ? dataAvaliacao.toString() : "";
        return new Date(dateString).toLocaleString();
    }
    changeType(ev: any) {
        this.projeto = ''
        if (ev === EnumTipoDemanda.Projeto) {
            this.getProjects();
            this.setColumnSizeProjects()
        }
        else {
            this.demanda.projetoId = null;
            this.setColumnSizeStart()
        }
    }

    getProjects() {
        this._projectsServiceProxy.getProjectAllActive()
            .subscribe(res => {
                this.projetostList = res;
            });
    }

    downloadAnexoGmud(anexo: GetGestaoMudancaNativaAnexoForViewDto) {
        this._fileDownloadService.downloadBinaryFile(
            anexo.fileStorageId,
            anexo.fileType,
            anexo.nomeAnexo,
            this.appSession.tenantId
        );
    }  

    getEnumTipoMudanca(tipoMudanca: TipoMudanca) {
        switch (tipoMudanca) {
            case TipoMudanca.Emergencial:
                return "Emergencial";
            case TipoMudanca.JanelaDeTransporte:
                return "Janela de transporte";         
            case TipoMudanca.Manual:
                return "Manual";
            case TipoMudanca.Planejado:
                return "Planejado";
            default:
                return "";
        }
    }

    getEnumRisco(risco: RiscoMudanca) {
        switch (risco) {
            case RiscoMudanca.Nenhum:
                return "Nenhum";
            case RiscoMudanca.Baixo:
                return "Baixo";         
            case RiscoMudanca.MuitoBaixo:
                return "Muito baixo";
            case RiscoMudanca.Moderado:
                return "Moderado";
            case RiscoMudanca.Alto:
                return "Alto";
            case RiscoMudanca.MuitoAlto:
                return "Muito alto";
            default:
                return "";
        }
    }

    finalizeDemand() {
        this.close();
        this.modalSave.emit(null);
    }

    recarregar(event?: LazyLoadEvent) {
        if (event && this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }
        
        this.primengTableHelper.showLoadingIndicator();
        this.primengTableHelper.totalRecordsCount = this.riskManagement.length;
        this.primengTableHelper.records = this.riskManagement;
        this.primengTableHelper.hideLoadingIndicator();
    }

    userAware() {
        this._demandasServiceProxy.updateRiskManagementConfirmed(this.demanda.id)
            .subscribe(res => {
                if (res){
                    this.riskManagementConfirmed = false;
                    return;
                }
            })
    }

    getRiskManagementConfiguration() {
        this._riskManagementConfigurationServices.getConfiguration()
            .subscribe(res => {
                this.riskManagementConfiguration = res;
            })
    }
}