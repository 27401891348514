import { AbpHttpInterceptor } from "abp-ng2-module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import * as ApiServiceProxies from "./service-proxies";

@NgModule({
    providers: [
        ApiServiceProxies.MinhasSenhasServiceProxy,
        ApiServiceProxies.BlockListPasswordsServiceProxy,
        ApiServiceProxies.AvaliacoesDeQualidadesServiceProxy,
        ApiServiceProxies.LiberacoesUsuariosServiceProxy,
        ApiServiceProxies.SapUsersServiceProxy,
        ApiServiceProxies.AmbientesServiceProxy,
        ApiServiceProxies.DemandasServiceProxy,
        ApiServiceProxies.AtividadesServiceProxy,
        ApiServiceProxies.ModulosServiceProxy,
        ApiServiceProxies.FornecedoresServiceProxy,
        ApiServiceProxies.CargosServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.ResponsaveisServiceProxy,
        ApiServiceProxies.ClientesFornecedoresServiceProxy,
        ApiServiceProxies.DemandasAnexosServiceProxy,
        ApiServiceProxies.DashTenantUsersServiceProxy,
        ApiServiceProxies.ConfigTimeSavingServiceProxy,
        ApiServiceProxies.AdUserServiceProxy,
        ApiServiceProxies.AvaliacaoAmbienteServiceProxy,
        ApiServiceProxies.GestaoMudancaServiceProxy,
        ApiServiceProxies.GestaoRiscoDemandaServiceProxy,
        ApiServiceProxies.IntegracoesServiceProxy,
        ApiServiceProxies.AcompanhamentoBuildServiceProxy,
        ApiServiceProxies.AvaliacaoPortalServiceProxy,
        ApiServiceProxies.DocumentacaoItemQualidadeServiceProxy,
        ApiServiceProxies.ConfiguracaoGMUDNativaServiceProxy,
        ApiServiceProxies.GestaoMudancaNativaServiceProxy,
        ApiServiceProxies.CronogramaAvaliacaoServiceProxy,
        ApiServiceProxies.EstruturasObjetosServiceProxy,
        ApiServiceProxies.PromptAIConfigServiceProxy,
        ApiServiceProxies.AvaliacaoDeAmbienteServiceProxy,
        ApiServiceProxies.ConfiguracaoCodeReviewServiceProxy,
        ApiServiceProxies.CodeReviewServiceProxy,
        ApiServiceProxies.NotificacaoPortalServiceProxy,
        ApiServiceProxies.GerenciamentoVersaoSapServiceProxy,
        ApiServiceProxies.ConfiguracaoAreaUsuarioServiceProxy,
        ApiServiceProxies.ConfigDeployAutomaticoServiceProxy,
        ApiServiceProxies.ProjectsServiceProxy,
        ApiServiceProxies.JanelaDeDeployServiceProxy,
        ApiServiceProxies.ImportStmsServiceProxy,
        ApiServiceProxies.SupportServiceProxy,
        ApiServiceProxies.RiskManagementConfigurationServiceProxy,
        ApiServiceProxies.GmudApprovalHierarchyServiceProxy,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AbpHttpInterceptor,
            multi: true,
        },
    ],
})
export class ServiceProxyModule {}
