import {
    Component,
    Injector,
    ViewEncapsulation,
    ViewChild,
    OnInit,
    ViewChildren,
    ElementRef,
} from "@angular/core";
import {
    DemandasServiceProxy,
    DemandaDto,
    EnumTipoDemanda,
    DemandaCardDto,
    EnumStatusDemanda,
    OrdenarCardsKanbanInput,
    AlteracaoDeStatusDaDemandaDto,
    UsuarioEnvolvidoDemandaSelect2,
    Select2ItemDto,
    EnumSituacaoDemanda,
    TenantSettingsEditDto,
    AmbienteMandanteDto,
    MandanteSapDto,
    ReflashPageStatusDto,
    GestaoMudancaServiceProxy,
    ProjectsServiceProxy,
    ProjetoDto,
    DemandaModuloLookupTableDto,
    RiskManagementConfigurationServiceProxy,
    RiskManagementConfigurationDto,
} from "@shared/service-proxies/service-proxies";
import { AppComponentBase } from "@shared/common/app-component-base";
import { CreateOrEditDemandaModalComponent } from "../create-or-edit-demanda-modal/create-or-edit-demanda-modal.component";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import * as _ from "lodash";
import { moveItemInArray } from "@angular/cdk/drag-drop";
import { OpenModalCardDto } from "../box-kanban/box-kanban.component";
import * as moment from "moment";
import { KanBanAmbienteModalComponent } from "../kanban-ambiente-modal/kanban-ambiente-modal.component";
import { CreateChangeManagerModalComponent } from "@app/main/create-change-manager-modal/create-change-manager-modal.component";
import { AppSessionService } from "@shared/common/session/app-session.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CreateManualChangeModalComponent } from "@app/main/create-change-manager-modal/create-manual-change-modal/create-manual-change-modal.component";
import { CreateRequestModalComponent } from "../create-request-modal/create-request-modal.component";
import { BuildTrackingModalComponent } from "../build-tracking-modal/build-tracking-modal.component";
import { CreateChangeManagerNativeModalComponent } from "@app/main/gestaoMudancas/create-change-manager-native-modal/create-change-manager-native-modal.component";
import { debug } from "console";
import { timeout } from "rxjs/operators";
import { Table } from "primeng/table";
import { Paginator } from "primeng/paginator";
import { LazyLoadEvent } from "primeng/api";

class DemandaCard extends DemandaCardDto {
    disabled: Boolean = false;

    constructor(card: DemandaCardDto) {
        super(card);
    }
}

@Component({
    templateUrl: "./kanban.component.html",
    styleUrls: ["./kanban.component.css"],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
})
export class KanbanComponent extends AppComponentBase implements OnInit {
    @ViewChild("createOrEditDemandaModal", { static: true })
    createOrEditDemandaModal: CreateOrEditDemandaModalComponent;
    @ViewChild("kanBanAmbienteModal", { static: true })
    kanBanAmbienteModal: KanBanAmbienteModalComponent;
    @ViewChild("createChangeManager", { static: true })
    createChangeManager: CreateChangeManagerModalComponent;
    @ViewChild("createChangeManagerNative", { static: true })
    createChangeManagerNative: CreateChangeManagerNativeModalComponent;
    @ViewChild("createManualChange", { static: true })
    createManualChange: CreateManualChangeModalComponent;
    @ViewChild("createRequestModal", { static: true })
    createRequestModal: CreateRequestModalComponent;
    @ViewChild("buildTrackingModal", { static: true })
    buildTrackingModal: BuildTrackingModalComponent;
    @ViewChild("dataTable", { static: true }) dataTable: Table;
    @ViewChild("paginator", { static: true }) paginator: Paginator;
    interval: NodeJS.Timeout;

    advancedFiltersAreShown = false;
    filterText = "";
    demandaIdFilter: number;
    demandaId: string;
    tituloFilter = "";
    descricaoFilter = "";
    tipoFilter = -1;
    referenciasFilter = "";
    maxEstimativaFilter: number;
    maxEstimativaFilterEmpty: number;
    minEstimativaFilter: number;
    minEstimativaFilterEmpty: number;
    maxPrevisaoTerminoDevFilter: moment.Moment;
    minPrevisaoTerminoDevFilter: moment.Moment;
    maxPrevisaoTerminoHomologFilter: moment.Moment;
    minPrevisaoTerminoHomologFilter: moment.Moment;
    userNameFilter = "";
    responsavelFilter = "";
    moduloNameFilter = "";
    statusFilter = -1;
    cardsBacklog: DemandaCard[] = [];
    cardsDesenvolvimento: DemandaCard[] = [];
    cardsQa: DemandaCard[] = [];
    cardsProducao: DemandaCard[] = [];
    loading = false;
    users: UsuarioEnvolvidoDemandaSelect2[] = [];
    usersSelected: UsuarioEnvolvidoDemandaSelect2[] = [];
    responsibles: UsuarioEnvolvidoDemandaSelect2[] = [];
    responsiblesSelected: UsuarioEnvolvidoDemandaSelect2[] = [];
    situacaoOriginal: number;
    enumTipoDemanda = EnumTipoDemanda;
    _entityTypeFullName = "QAMetrik.Demandas.Demanda";
    entityHistoryEnabled = false;
    settings: TenantSettingsEditDto = new TenantSettingsEditDto();
    numeroGestaoMudanca: string;
    request: string;
    moduloSap: DemandaModuloLookupTableDto[] = [];
    moduloSapSelected: DemandaModuloLookupTableDto[] = [];
    project: ProjetoDto[] = [];
    projectsSelected: ProjetoDto[] = [];
    connectionSap: boolean = false;
    hasDemandaEstagnada: boolean = false;
    listaDemandasEstagnadas: string[] = [];
    showAlertMessage: boolean = false;
    riskManagementConfig: RiskManagementConfigurationDto =
        new RiskManagementConfigurationDto();

    constructor(
        injector: Injector,
        private _demandasServiceProxy: DemandasServiceProxy,
        private _sessionService: AppSessionService,
        private _activatedRoute: ActivatedRoute,
        private _projectSeriveProxy: ProjectsServiceProxy,
        private _riskManagementConfigService: RiskManagementConfigurationServiceProxy
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.entityHistoryEnabled = this.setIsEntityHistoryEnabled();
        let usuarioLogado = new UsuarioEnvolvidoDemandaSelect2();
        usuarioLogado.label = this.appSession.user.name;
        usuarioLogado.value = this.appSession.user.id.toString();
        this.usersSelected = [usuarioLogado];
        this.buscarProjetos();
        this.buscarUsuarios(this.appSession.tenantId);
        this.buscarModulosSap();
        this.getDemandas();
        //this.getRiskManagementConfiguration();

        this._activatedRoute.params.subscribe((params) => {
            this.demandaId = params["demandaId"];
            if (this.demandaId != undefined) {
                let ev = new OpenModalCardDto();
                ev.id = this.demandaId;
                ev.tabId = "0";
                this.emitEventOpenModal(ev);
            }
        });

        abp.event.on("abp.notifications.received", (userNotification) => {
            this.reloadPage(null);
        });
    }

    private setIsEntityHistoryEnabled(): boolean {
        let customSettings = (abp as any).custom;
        return (
            customSettings.EntityHistory &&
            customSettings.EntityHistory.isEnabled &&
            _.filter(
                customSettings.EntityHistory.enabledEntities,
                (entityType) => entityType === this._entityTypeFullName
            ).length === 1
        );
    }

    getDemandas() {
        if (
            this.minPrevisaoTerminoHomologFilter >
            this.maxPrevisaoTerminoHomologFilter
        ) {
            this.notify.warn(this.l("InvalidDateRange"));
            return;
        }

        this.loading = true;
        this.primengTableHelper.showLoadingIndicator();
        let usuarios = [];
        this.usersSelected.forEach((el) => {
            usuarios.push(parseInt(el.value));
        });

        let responsaveis = [];

        this.responsiblesSelected.forEach((el) => {
            responsaveis.push(parseInt(el.value));
        });

        let project = [];

        this.projectsSelected.forEach((el) => {
            project.push(el.nome);
        });

        let moduloSap = [];

        this.moduloSapSelected.forEach((el) => {
            moduloSap.push(el.displayName);
        });

        this._demandasServiceProxy
            .getAllCards(
                this.filterText,
                this.tituloFilter,
                this.descricaoFilter,
                this.tipoFilter,
                this.referenciasFilter,
                this.maxEstimativaFilter == null
                    ? this.maxEstimativaFilterEmpty
                    : this.maxEstimativaFilter,
                this.minEstimativaFilter == null
                    ? this.minEstimativaFilterEmpty
                    : this.minEstimativaFilter,
                this.maxPrevisaoTerminoDevFilter,
                this.minPrevisaoTerminoDevFilter,
                this.maxPrevisaoTerminoHomologFilter,
                this.minPrevisaoTerminoHomologFilter,
                this.statusFilter,
                this.userNameFilter,
                responsaveis,
                this.moduloNameFilter,
                "",
                this.demandaIdFilter,
                usuarios,
                this.numeroGestaoMudanca,
                this.request,
                moduloSap,
                project,
                "0",
                0,
                9999
            )
            .subscribe((result) => {
                this.getConnectionSap();
                this.loading = false;
                this.cardsBacklog = result
                    .filter((x) => x.status === EnumStatusDemanda.Backlog)
                    .map((x) => new DemandaCard(x))
                    .sort(
                        (itemA, itemB) =>
                            itemB.demandaSapId - itemA.demandaSapId
                    );
                this.cardsDesenvolvimento = result
                    .filter(
                        (x) => x.status === EnumStatusDemanda.Desenvolvimento
                    )
                    .map((x) => new DemandaCard(x))
                    .sort(
                        (itemA, itemB) =>
                            itemB.demandaSapId - itemA.demandaSapId
                    );
                this.cardsQa = result
                    .filter((x) => x.status === EnumStatusDemanda.Homologacao)
                    .map((x) => new DemandaCard(x))
                    .sort(
                        (itemA, itemB) =>
                            itemB.demandaSapId - itemA.demandaSapId
                    );
                this.cardsProducao = result
                    .filter((x) => x.status === EnumStatusDemanda.Producao)
                    .map((x) => new DemandaCard(x))
                    .sort(
                        (itemA, itemB) =>
                            itemB.demandaSapId - itemA.demandaSapId
                    );
                this.primengTableHelper.hideLoadingIndicator();
            });
    }

    createDemanda(): void {
        this.createOrEditDemandaModal.show();
    }

    drop(event: any) {
        if (event.previousContainer == event.container) {
            moveItemInArray(
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
            let ordenarFilaKanban = new OrdenarCardsKanbanInput();
            ordenarFilaKanban.guidCardMovimentado =
                event.previousContainer.data[event.currentIndex].id;
            ordenarFilaKanban.sequencia = event.currentIndex + 1;
            this._demandasServiceProxy
                .ordenarFilaKanban(ordenarFilaKanban)
                .subscribe(() => {
                    this.notify.success(this.l("SuccessAlteradoStatusDemanda"));
                    this.reloadPage(event);
                });
        } else {
            const card: DemandaCard =
                event.previousContainer.data[event.previousIndex];

            var prEstagio = parseInt(event.container.id);
            var oldEstagio = parseInt(event.previousContainer.id);
            if (prEstagio < oldEstagio || prEstagio - oldEstagio > 1) {
                this.notify.warn(this.l("InvalidMove"));
                return;
            }
            if (card.situacao == EnumSituacaoDemanda.EmAnalise) {
                this.notify.warn(this.l("InvalidMoveOnAnalysis"));
                return;
            }
            this.situacaoOriginal =
                event.previousContainer.data[event.previousIndex].situacao;
            event.previousContainer.data[event.previousIndex].situacao =
                EnumSituacaoDemanda.AguardandoLiberacaoDeAmbiente;
            this.demandaId = card.id;
            if (prEstagio == 1) {
                this.createRequestModal.show(
                    event,
                    card.status,
                    card.userId,
                    card.id
                );
                return;
            }
            this.kanBanAmbienteModal.show(
                event,
                card.status,
                card.userId,
                card.id,
                event.container.versaoTeste
            );
        }
    }

    restoreIcon(event: any) {
        event.eventDrop.previousContainer.data[
            event.eventDrop.previousIndex
        ].situacao = this.situacaoOriginal;
    }

    createRequest(event: any) {
        const dto = new AlteracaoDeStatusDaDemandaDto();
        dto.listaDeCriacao = event.environmentList;
        dto.novoStatus = event.idEstagio;
        dto.demandaId = event.demandaId;
        this.criarRequestSap(dto, null);
    }

    criarRequestSap(dto: AlteracaoDeStatusDaDemandaDto, ev) {
        this._demandasServiceProxy.criarRequest(dto).subscribe(() => {
            this.notify.success("Request criada com sucesso.");
            this.reloadPage(ev);
        });
    }

    atualizaObjetosDemanda(dto: AlteracaoDeStatusDaDemandaDto, ev) {
        this._demandasServiceProxy
            .updateObjectsDemanda(dto.demandaId, false)
            .subscribe((result) => {
                if (result) {
                    this.alterarStatusDaDemanda(dto, ev);
                } else {
                    this.notify.error("Erro ao atualizar objetos da demanda");
                    return;
                }
            });
    }

    alterarStatusDaDemanda(dto: AlteracaoDeStatusDaDemandaDto, ev) {
        const cardList: DemandaCard[] = [
            ...this.cardsBacklog,
            ...this.cardsDesenvolvimento,
            ...this.cardsQa,
            ...this.cardsProducao,
        ];
        const card = cardList.find((item) => item.id == dto.demandaId);
        card.disabled = true;

        this._demandasServiceProxy
            .alterarStatusDaDemanda(dto)
            .subscribe((x) => {
                this.solicitaAvaliacaoQualidade(dto, ev);
                card.disabled = false;
            });
    }

    solicitaAvaliacaoQualidade(dto: AlteracaoDeStatusDaDemandaDto, ev) {
        this._demandasServiceProxy
            .solicitarAvaliacaoQualidade(dto)
            .subscribe(() => {
                this.notify.success(
                    this.l("Avaliação de qualidade solicitada com sucesso.")
                );
                this.interval = setInterval(
                    this.getReflashDemandState.bind(this),
                    5000
                );
                this.reloadPage(ev);
            });
        this.reloadPage(ev);
    }

    getReflashDemandState(ev) {
        this._demandasServiceProxy
            .reflashDemandState(this.demandaId)
            .subscribe((res: ReflashPageStatusDto) => {
                if (res.situacaoDemanda == EnumSituacaoDemanda.SemStatus) {
                    this.notify.success(
                        this.l("AvaliacaoQualidadeAprovada", res.demandaSapId)
                    );
                    clearInterval(this.interval);
                    location.reload();
                    return;
                }

                if (res.situacaoDemanda == EnumSituacaoDemanda.Aprovado) {
                    this.notify.success(
                        this.l("AvaliacaoQualidadeAprovada", res.demandaSapId)
                    );
                    clearInterval(this.interval);
                    location.reload();
                    return;
                }

                if (res.situacaoDemanda == EnumSituacaoDemanda.Reprovado) {
                    this.notify.error(
                        this.l("AvaliacaoQualidadeDebitos", res.demandaSapId)
                    );
                    clearInterval(this.interval);
                    location.reload();
                    return;
                }

                if (
                    res.situacaoDemanda == EnumSituacaoDemanda.FalhaNoProcesso
                ) {
                    this.notify.error(
                        this.l("AvaliacaoQualidadeDebitos", res.demandaSapId)
                    );
                    clearInterval(this.interval);
                    location.reload();
                    return;
                }

                if (res.situacaoDemanda == EnumSituacaoDemanda.CodeReview) {
                    clearInterval(this.interval);
                    location.reload();
                    return;
                }

                if (
                    res.situacaoDemanda == EnumSituacaoDemanda.RiscoDeTransporte
                ) {
                    clearInterval(this.interval);
                    location.reload();
                    return;
                }
            });
    }

    emitEventOpenModal(ev: OpenModalCardDto) {
        if (ev.gerarVersaoTeste != null) {
            let fakeMove = {
                versaoDeTeste: true,
                previousContainer: {
                    data: [ev.gerarVersaoTeste],
                },
                container: {
                    id: 2,
                    versaoTeste: true,
                },
                previousIndex: 0,
                eventDrop: {
                    container: {
                        id: ev.gerarVersaoTeste.status,
                    },
                },
            };
            this.drop(fakeMove);
            return;
        }

        this.createOrEditDemandaModal.show(ev.id, ev.tabId);
    }

    detalhesAdicionado(ev: any) {
        if (ev.ambientes == null) {
            this.restoreIcon(ev);
            return;
        }
        let card =
            ev.eventDrop.previousContainer.data[ev.eventDrop.previousIndex];
        let dto = new AlteracaoDeStatusDaDemandaDto();
        dto.demandaId = card.id;
        dto.versaoDeTeste = ev.eventDrop.versaoDeTeste;
        dto.novoStatus = parseInt(ev.eventDrop.container.id);
        dto.equalizarAmbiente = ev.equalizarAmbiente;
        dto.skipQuality = ev.skipQuality;

        if (ev.eventDrop.container.versaoTeste) {
            dto.novoStatus = 0;
            Object.keys(EnumStatusDemanda).forEach((item, index) => {
                if (
                    EnumStatusDemanda[item] ==
                    ev.eventDrop.eventDrop.container.id
                ) {
                    dto.novoStatus = index;
                    return;
                }
            });
        }

        const statusValue = Object.keys(EnumStatusDemanda)[dto.novoStatus];

        dto.listaDeAmbientes = ev.ambientes;
        dto.listaDeTasks = ev.tasksSapsSelecionadas;
        dto.listaDeRequests = ev.requestsSapsSelecionadas;

        if (ev.usuarioSap) {
            dto.usuarioSapId = ev.usuarioSap.value;
        }
        ev.eventDrop.previousContainer.data[ev.eventDrop.previousIndex];
        dto.userQueMoveuId = this.appSession.userId;

        /*
        if (statusValue == EnumStatusDemanda.Producao && this.riskManagementConfig.activeRisk) {
            this.requestsRiskAssessment(dto);
            this.reloadPage(ev);
            return;
        }

        this.requestsRiskAssessment(dto);
        */

        if (
            this._sessionService.tenant.tennantSettings.changeManager
                .isEnabled &&
            statusValue == EnumStatusDemanda.Producao
        ) {
            this.createChangeManager.show(dto.demandaId, dto);
            return;
        }

        if (
            this._sessionService.tenant.tennantSettings.changeManager
                .isEnabledNative &&
            statusValue == EnumStatusDemanda.Producao
        ) {
            this.createChangeManagerNative.show(dto.demandaId, dto);
            return;
        }

        //Atualizar objetos da demanda
        this.atualizaObjetosDemanda(dto, ev);
    }

    goToBuildTracking(event) {
        this.buildTrackingModal.show(event);
    }

    resetarFiltros() {
        this.tipoFilter = -1;
        this.filterText = "";
        this.tituloFilter = "";
        this.descricaoFilter = "";
        this.referenciasFilter = "";
        this.maxPrevisaoTerminoDevFilter = null;
        this.minPrevisaoTerminoDevFilter = null;
        this.maxPrevisaoTerminoHomologFilter = null;
        this.minPrevisaoTerminoHomologFilter = null;
        this.statusFilter = -1;
        this.userNameFilter = "";
        this.moduloNameFilter = "";
        this.maxEstimativaFilter = null;
        this.minEstimativaFilter = null;
        this.demandaIdFilter = undefined;
        this.usersSelected = [];
        this.responsiblesSelected = [];
        this.request = "";
        this.moduloSap = [];
        this.projectsSelected = [];
    }

    reloadPage(ev: any) {
        this.getDemandas();
        this.buildTrackingModal.close();
    }

    buscarUsuarios(tenantId: number) {
        this._demandasServiceProxy
            .getDadosCompletosForSelect2(tenantId)
            .subscribe((x) => {
                this.users = x;
                this.users.forEach((user) => {
                    if (user.value === this.appSession.userId.toString()) {
                        this.usersSelected = [].concat(user);
                    }
                });

                this.responsibles = x;
                this.responsibles.forEach((user) => {
                    if (user.value === this.appSession.userId.toString()) {
                        this.responsiblesSelected = [].concat(user);
                    }
                });
            });
    }

    buscarProjetos() {
        this._projectSeriveProxy.getProjectAll().subscribe((x) => {
            this.project = x;
        });
    }
    buscarModulosSap(event?: LazyLoadEvent) {
        this._demandasServiceProxy
            .getAllModuloForLookupTable(
                undefined,
                undefined,
                undefined,
                undefined
            )
            .subscribe((result) => {
                this.moduloSap = result.items;
            });
    }

    clear(item: Select2ItemDto) {
        this.usersSelected = [].concat(
            this.usersSelected.filter((x) => x.value !== item.value)
        );
    }
    clear2(item: Select2ItemDto) {
        this.responsiblesSelected = [].concat(
            this.responsiblesSelected.filter((x) => x.value !== item.value)
        );
    }

    clearProjects(item: ProjetoDto) {
        this.projectsSelected = [].concat(
            this.projectsSelected.filter((x) => x.nome !== item.nome)
        );
    }

    clearModuloSap(item: DemandaModuloLookupTableDto) {
        this.moduloSapSelected = [].concat(
            this.moduloSapSelected.filter(
                (x) => x.displayName !== item.displayName
            )
        );
    }
    openManualChangeModal(
        alteracaoDeStatusDaDemandaDto: AlteracaoDeStatusDaDemandaDto
    ) {
        this.createManualChange.show(
            this.demandaId,
            alteracaoDeStatusDaDemandaDto
        );
    }

    toggleShowAlertMessage() {
        this.showAlertMessage = !this.showAlertMessage;
    }

    getConnectionSap() {
        this._demandasServiceProxy.connectionTesterDemand().subscribe((res) => {
            this.connectionSap = res ? false : true;
        });
    }
}
