import {
    Component,
    Injector,
    ViewEncapsulation,
    ViewChild,
    OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
    DemandasServiceProxy,
    DemandaDto,
    EnumTipoDemanda,
    EnumStatusDemanda,
    UsuarioEnvolvidoDemandaSelect2,
    Select2ItemDto,
} from "@shared/service-proxies/service-proxies";
import { AppComponentBase } from "@shared/common/app-component-base";
import { TokenAuthServiceProxy } from "@shared/service-proxies/service-proxies";
import { CreateOrEditDemandaModalComponent } from "./create-or-edit-demanda-modal/create-or-edit-demanda-modal.component";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { Table } from "primeng/table";
import { Paginator } from "primeng/paginator";
import { LazyLoadEvent } from "primeng/api";
import { FileDownloadService } from "@shared/utils/file-download.service";
import { EntityTypeHistoryModalComponent } from "@app/shared/common/entityHistory/entity-type-history-modal.component";
import * as _ from "lodash";
import * as moment from "moment";

@Component({
    templateUrl: "./demandas.component.html",
    styleUrls: ["./demandas.component.css"],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()],
})
export class DemandasComponent extends AppComponentBase implements OnInit {
    @ViewChild("createOrEditDemandaModal", { static: true })
    createOrEditDemandaModal: CreateOrEditDemandaModalComponent;
    @ViewChild("entityTypeHistoryModal", { static: true })
    entityTypeHistoryModal: EntityTypeHistoryModalComponent;
    @ViewChild("dataTable", { static: true }) dataTable: Table;
    @ViewChild("paginator", { static: true }) paginator: Paginator;

    advancedFiltersAreShown = false;
    filterText = "";
    tituloFilter = "";
    descricaoFilter = "";
    tipoFilter = -1;
    referenciasFilter = "";
    maxEstimativaFilter: number;
    maxEstimativaFilterEmpty: number;
    minEstimativaFilter: number;
    minEstimativaFilterEmpty: number;
    maxPrevisaoTerminoDevFilter: moment.Moment;
    minPrevisaoTerminoDevFilter: moment.Moment;
    maxPrevisaoTerminoHomologFilter: moment.Moment;
    minPrevisaoTerminoHomologFilter: moment.Moment;
    statusFilter = -1;
    userNameFilter = "";
    moduloNameFilter = "";

    enumTipoDemanda = EnumTipoDemanda;
    enumStatusDemanda = EnumStatusDemanda;
    archivedFilter = "-1";

    _entityTypeFullName = "QAMetrik.Demandas.Demanda";
    entityHistoryEnabled = false;
    demandaIdFilter: number;
    users: UsuarioEnvolvidoDemandaSelect2[] = [];
    usersSelected: UsuarioEnvolvidoDemandaSelect2[] = [];
    responsibles: UsuarioEnvolvidoDemandaSelect2[] = [];
    responsiblesSelected: UsuarioEnvolvidoDemandaSelect2[] = [];
    numeroGestaoMudanca = "";
    request: string;
    moduloSap: string[] = [];
    projeto: string[] = [];

    constructor(
        injector: Injector,
        private _demandasServiceProxy: DemandasServiceProxy,
        private _fileDownloadService: FileDownloadService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this.entityHistoryEnabled = this.setIsEntityHistoryEnabled();
        let usuarioLogado = new UsuarioEnvolvidoDemandaSelect2();
        usuarioLogado.label = this.appSession.user.name;
        usuarioLogado.value = this.appSession.user.id.toString();
        this.usersSelected = [usuarioLogado];
        this.buscarUsuarios(this.appSession.tenantId);
    }

    private setIsEntityHistoryEnabled(): boolean {
        let customSettings = (abp as any).custom;
        return (
            customSettings.EntityHistory &&
            customSettings.EntityHistory.isEnabled &&
            _.filter(
                customSettings.EntityHistory.enabledEntities,
                (entityType) => entityType === this._entityTypeFullName
            ).length === 1
        );
    }

    getDemandas(event?: LazyLoadEvent) {
        if (this.primengTableHelper.shouldResetPaging(event)) {
            this.paginator.changePage(0);
            return;
        }

        if (
            this.minPrevisaoTerminoHomologFilter >
            this.maxPrevisaoTerminoHomologFilter
        ) {
            this.notify.warn(this.l("InvalidDateRange"));
            return;
        }

        let responsaveis = [];

        this.responsiblesSelected.forEach((el) => {
            responsaveis.push(parseInt(el.value));
        });

        this.primengTableHelper.showLoadingIndicator();
        let usuarios = [];
        this.usersSelected.forEach((el) => {
            usuarios.push(parseInt(el.value));
        });
        this._demandasServiceProxy
            .getAll(
                this.filterText,
                this.tituloFilter,
                this.descricaoFilter,
                this.tipoFilter,
                this.referenciasFilter,
                this.maxEstimativaFilter == null
                    ? this.maxEstimativaFilterEmpty
                    : this.maxEstimativaFilter,
                this.minEstimativaFilter == null
                    ? this.minEstimativaFilterEmpty
                    : this.minEstimativaFilter,
                this.maxPrevisaoTerminoDevFilter,
                this.minPrevisaoTerminoDevFilter,
                this.maxPrevisaoTerminoHomologFilter,
                this.minPrevisaoTerminoHomologFilter,
                this.statusFilter,
                this.userNameFilter,
                responsaveis,
                this.moduloNameFilter,
                this.archivedFilter,
                this.demandaIdFilter,
                usuarios,
                this.numeroGestaoMudanca,
                this.request,
                this.moduloSap,
                this.projeto,
                this.primengTableHelper.getSorting(this.dataTable),
                this.primengTableHelper.getSkipCount(this.paginator, event),
                this.primengTableHelper.getMaxResultCount(this.paginator, event)
            )
            .subscribe((result) => {
                this.primengTableHelper.totalRecordsCount = result.totalCount;
                this.primengTableHelper.records = result.items;
                this.primengTableHelper.hideLoadingIndicator();
            });
    }

    reloadPage(): void {
        this.paginator.changePage(this.paginator.getPage());
    }

    createDemanda(): void {
        this.createOrEditDemandaModal.show();
    }

    showHistory(demanda: DemandaDto): void {
        this.entityTypeHistoryModal.show({
            entityId: demanda.id.toString(),
            entityTypeFullName: this._entityTypeFullName,
            entityTypeDescription: demanda.descricao,
        });
    }

    deleteDemanda(demanda: DemandaDto): void {
        this.message.confirm("", "", (isConfirmed) => {
            if (isConfirmed) {
                this._demandasServiceProxy.delete(demanda.id).subscribe(() => {
                    this.reloadPage();
                    this.notify.success(this.l("SuccessfullyDeleted"));
                });
            }
        });
    }

    exportToExcel(): void {
        let responsaveis = [];

        this.responsiblesSelected.forEach((el) => {
            responsaveis.push(parseInt(el.value));
        });

        this.primengTableHelper.showLoadingIndicator();
        let usuarios = [];
        this.usersSelected.forEach((el) => {
            usuarios.push(parseInt(el.value));
        });

        this._demandasServiceProxy
            .getDemandasToExcel(
                this.filterText,
                this.tituloFilter,
                this.descricaoFilter,
                this.tipoFilter,
                this.referenciasFilter,
                this.maxEstimativaFilter == null
                    ? this.maxEstimativaFilterEmpty
                    : this.maxEstimativaFilter,
                this.minEstimativaFilter == null
                    ? this.minEstimativaFilterEmpty
                    : this.minEstimativaFilter,
                this.maxPrevisaoTerminoDevFilter,
                this.minPrevisaoTerminoDevFilter,
                this.maxPrevisaoTerminoHomologFilter,
                this.minPrevisaoTerminoHomologFilter,
                this.statusFilter,
                this.userNameFilter,
                responsaveis,
                this.moduloNameFilter,
                this.archivedFilter,
                this.demandaIdFilter,
                usuarios,
                this.numeroGestaoMudanca,
                this.request,
                this.moduloSap,
                this.projeto,
                this.primengTableHelper.getSorting(this.dataTable),
                this.primengTableHelper.getSkipCount(this.paginator, null),
                this.primengTableHelper.getMaxResultCount(this.paginator, null)
            )
            .subscribe((result) => {
                this._fileDownloadService.downloadTempFile(result);
                this.primengTableHelper.hideLoadingIndicator();
            });
    }

    archiveDemanda(id: string) {
        this.message.confirm("", "", (isConfirmed) => {
            if (isConfirmed) {
                this._demandasServiceProxy.arquivar(id).subscribe(() => {
                    this.reloadPage();
                    this.notify.success(this.l("SuccessfullyArchived"));
                });
            }
        });
    }

    unachiveDemanda(id: string) {
        this.message.confirm("", "", (isConfirmed) => {
            if (isConfirmed) {
                this._demandasServiceProxy.unarchive(id).subscribe(() => {
                    this.reloadPage();
                    this.notify.success(this.l("SuccessfullyUnarchived"));
                });
            }
        });
    }

    buscarUsuarios(tenantId: number) {
        this._demandasServiceProxy
            .getDadosCompletosForSelect2(tenantId)
            .subscribe((x) => {
                this.users = x;
                this.users.forEach((user) => {
                    if (user.value === this.appSession.userId.toString()) {
                        this.usersSelected = [].concat(user);
                    }
                });

                this.responsibles = x;
                this.responsibles.forEach((user) => {
                    if (user.value === this.appSession.userId.toString()) {
                        this.responsiblesSelected = [].concat(user);
                    }
                });
            });
    }
    clear(item: Select2ItemDto) {
        this.usersSelected = [].concat(
            this.usersSelected.filter((x) => x.value !== item.value)
        );
    }

    clear2(item: Select2ItemDto) {
        this.responsiblesSelected = [].concat(
            this.responsiblesSelected.filter((x) => x.value !== item.value)
        );
    }

    resetarFiltros() {
        this.filterText = "";
        this.tipoFilter = -1;
        this.tituloFilter = "";
        this.descricaoFilter = "";
        this.referenciasFilter = "";
        this.maxPrevisaoTerminoDevFilter = null;
        this.minPrevisaoTerminoDevFilter = null;
        this.maxPrevisaoTerminoHomologFilter = null;
        this.minPrevisaoTerminoHomologFilter = null;
        this.statusFilter = -1;
        this.userNameFilter = "";
        this.moduloNameFilter = "";
        this.maxEstimativaFilter = null;
        this.minEstimativaFilter = null;
        this.demandaIdFilter = undefined;
        this.usersSelected = [];
        this.responsiblesSelected = [];
        this.getDemandas();
    }

    allowedOps(demanda: DemandaDto): string {
        let ops = "";
        if (demanda.arquivado) ops += "des;";
        if (
            !demanda.arquivado &&
            demanda.status == EnumStatusDemanda.Homologacao
        )
            ops += "arquivar;";
        if (demanda.status == EnumStatusDemanda.Backlog) ops += "excluir;";
        if (
            demanda.status == EnumStatusDemanda.Desenvolvimento &&
            demanda.tasksSapsDtos == null
        ) {
            ops += "excluir;" + (demanda.arquivado ? "des;" : "arquivar;");
        }

        return ops;
    }
}
