<div [@routerTransition]>
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{"Users" | localize}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{"UsersHeaderInfo" | localize}}
            </span>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">
                <div class="btn-group" dropdown>
                    <button dropdownToggle type="button" class="btn btn-outline-success dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="far fa-file-excel"></i> {{"ExcelOperations" | localize}}
                    </button>
                    <div class="dropdown-menu dropdown-menu-right dropdown-excel-operations" *dropdownMenu>
                        <a class="dropdown-item" id="ExportUsersToExcelButton" (click)="exportToExcel()" href="javascript:;">
                            <i class="fa fa-download" style="font-size: 1rem;"></i>
                            {{'ExportToExcel' | localize}}
                        </a>
                        <a href="javascript:;" *ngIf="isGranted('Pages.Administration.Users.Create')" class="no-padding">
                            <span class="fileinput-button">
                                <p-fileUpload customUpload="true"
                                        name="ExcelFileUpload"
                                        #ExcelFileUpload
                                        maxFileSize="10000000"
                                        auto="auto"
                                        accept=".csv,.xls,.xlsx"
                                        (uploadHandler)="uploadExcel($event)"
                                        (onError)="onUploadExcelError()"
                                        chooseLabel="{{'ImportFromExcel' | localize}}">
                                </p-fileUpload>
                            </span>
                        </a>
                        <div class="dropdown-divider"></div>
                        <span class="dropdown-item-text text-muted pl-3" href="#">
                            <small [innerHTML]="l('ImportToExcelSampleFileDownloadInfo', '<a href=/assets/sampleFiles/ImportUsersSampleFile.xlsx>' + l('ClickHere') + '</a>')"></small>
                        </span>
                    </div>
                </div>
                <div class="btn-group dropdown" dropdown container="body" *ngIf="isGranted('Pages.Administration.Users.Create')">
                    <button dropdownToggle class="dropdown-toggle btn btn-sm btn-primary">
                        <i class="fa fa-plus"></i><span class="caret"></span> {{"CreateNewUser" | localize}}
                    </button>
                    <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                        <li>
                            <a href="javascript:;" (click)="createUser()">
                                {{'DevopsUser' | localize}}</a>
                        </li>
                        <li *ngIf="appSession.tenantId">
                            <a href="javascript:;"  (click)="createSapUserModal.show()">
                                {{'SapUser' | localize}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="kt-content">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
                <form class="kt-form" autocomplete="new-password">
                    <div>
                        <div class="row align-items-center mb-4">
                            <div class="col-xl-12">
                                <div class="form-group m-form__group align-items-center">
                                    <div class="input-group">
                                        <input [(ngModel)]="filterText" name="filterText" autoFocus class="form-control m-input" [placeholder]="l('SearchWithThreeDot')" type="text">
                                        <span class="input-group-btn">
                                            <button (click)="getUsers()" class="btn btn-primary" type="submit"><i class="flaticon-search-1" [attr.aria-label]="l('Search')"></i></button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-4" [hidden]="!advancedFiltersAreShown">
                            <div [ngClass]="{'col-md-6': ('Pages.Administration.Roles' | permission), 'col-md-12':!isGranted('Pages.Administration.Roles')}">
                                <div class="form-group">
                                    <permission-combo name="permissionFilter" [(ngModel)]="selectedPermission"></permission-combo>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="'Pages.Administration.Roles' | permission">
                                <div class="form-group">
                                    <role-combo name="roleFilter" [(ngModel)]="role"></role-combo>
                                </div>
                            </div>

                            <div class="col-md-6" *ngIf="!appSession.tenantId" >
                                <div class="kt-checkbox-list">
                                    <label for="UsersTable_OnlyLockedUsers" class="kt-checkbox">
                                        <input id="UsersTable_OnlyLockedUsers" type="checkbox" name="OnlyLockedUsers" [(ngModel)]="onlyLockedUsers">
                                        {{"OnlyLockedUsers" | localize}}
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="appSession.tenantId" >
                                <div class="kt-checkbox-list">
                                    <label for="UsersTable_OnlyLockedUsers" class="kt-checkbox">
                                        <input id="UsersTable_OnlyLockedUsers" type="checkbox" name="OnlyInactiveUsers" [(ngModel)]="onlyInactiveUsers">
                                        {{"OnlyInactiveUsers" | localize}}
                                        <span></span>
                                    </label>
                                </div>
                            </div>

                        </div>

                        <div class="row mb-4" *ngIf="advancedFiltersAreShown">
                            <div class="col-sm-12 text-right">
                                <button class="btn btn-primary" (click)="getUsers()"><i class="fa fa-sync"></i> {{"Refresh" | localize}}</button>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-sm-12">
                                <span class="clickable-item text-muted" *ngIf="!advancedFiltersAreShown" (click)="advancedFiltersAreShown=!advancedFiltersAreShown"><i class="fa fa-angle-down"></i> {{"ShowAdvancedFilters" | localize}}</span>
                                <span class="clickable-item text-muted" *ngIf="advancedFiltersAreShown" (click)="advancedFiltersAreShown=!advancedFiltersAreShown"><i class="fa fa-angle-up"></i> {{"HideAdvancedFilters" | localize}}</span>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="row align-items-center">
                    <!--<Primeng-TurboTable-Start>-->
                    <div class="primeng-datatable-container"
                         [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable
                                 (onLazyLoad)="getUsers($event)"
                                 [value]="primengTableHelper.records"
                                 rows="{{primengTableHelper.defaultRecordsCountPerPage}}"
                                 [paginator]="false"
                                 [lazy]="true"
                                 [scrollable]="true"
                                 ScrollWidth="100%"
                                 [responsive]="primengTableHelper.isResponsive"
                                 [resizableColumns]="primengTableHelper.resizableColumns">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 130px" [hidden]="!isGrantedAny('Pages.Administration.Users.Impersonation', 'Pages.Administration.Users.Edit', 'Pages.Administration.Users.ChangePermissions', 'Pages.Administration.Users.Delete')">{{'Actions' | localize}}</th>
                                    <th style="width: 150px" pSortableColumn="username" *ngIf="!appSession.tenantId">
                                        {{'UserName' | localize}}
                                        <p-sortIcon field="username"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px" pSortableColumn="name">
                                        {{'Name' | localize}}
                                        <p-sortIcon field="name"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px" pSortableColumn="surname">
                                        {{'Surname' | localize}}
                                        <p-sortIcon field="surname"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px" pSortableColumn="userName">
                                        {{'Login' | localize}}
                                        <p-sortIcon field="userName"></p-sortIcon>
                                    </th>
                                    <th style="width: 150px">
                                        {{'Roles' | localize}}
                                    </th>
                                    <th style="width: 250px" pSortableColumn="emailAddress">
                                        {{'EmailAddress' | localize}}
                                        <p-sortIcon field="emailAddress"></p-sortIcon>
                                    </th>
                                    <th style="width: 250px" pSortableColumn="cpf">
                                        {{'Cpf' | localize}}
                                        <p-sortIcon field="cpf"></p-sortIcon>
                                    </th>
                                    <th style="width: 200px" pSortableColumn="isEmailConfirmed">
                                        {{'EmailConfirm' | localize}}
                                        <p-sortIcon field="isEmailConfirmed"></p-sortIcon>
                                    </th>
                                    <th style="width: 100px" pSortableColumn="isActive">
                                        {{'Active' | localize}}
                                        <p-sortIcon field="isActive"></p-sortIcon>
                                    </th>
                                    <th style="width: 200px" pSortableColumn="creationTime">
                                        {{'CreationTime' | localize}}
                                        <p-sortIcon field="creationTime"></p-sortIcon>
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td style="width: 130px"
                                        [hidden]="!isGrantedAny('Pages.Administration.Users.Impersonation', 'Pages.Administration.Users.Edit', 'Pages.Administration.Users.ChangePermissions', 'Pages.Administration.Users.Delete')">
                                        <div class="btn-group dropdown" dropdown container="body">
                                            <button dropdownToggle class="dropdown-toggle btn btn-sm btn-primary">
                                                <i class="fa fa-cog"></i><span class="caret"></span> {{"Actions" | localize}}
                                            </button>
                                            <ul class="dropdown-menu" *dropdownMenu>
                                                <li>
                                                    <a href="javascript:;" *ngIf="('Pages.Administration.Users.Impersonation' | permission) && record.id !== appSession.userId && !isImpersonated"
                                                       (click)="_impersonationService.impersonate(record.id, appSession.tenantId)">{{'LoginAsThisUser' | localize}}</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;" *ngIf="'Pages.Administration.Users.Edit' | permission"
                                                       (click)="createOrEditUserModal.show(record.id)">{{'Edit' | localize}}</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;" *ngIf="'Pages.Administration.Users.ChangePermissions' | permission"
                                                       (click)="editUserPermissionsModal.show(record.id, record.userName)">{{'Permissions' | localize}}</a>
                                                </li>
                                                <li>
                                                    <a href="javascript:;" *ngIf="'Pages.Administration.Fornecedores.Tenant' | permission"
                                                       (click)="maquinasUserModalComponent.show(record.id, record.userName)">{{'Maquinas' | localize}}</a>
                                                </li>    
                                                
                                                <li>
                                                    <a href="javascript:;" *ngIf="isLocked(record) && 'Pages.Administration.Users.ChangePermissions' | permission"
                                                       (click)="unlockUser(record)">{{'Unlock' | localize}}</a>
                                                </li>

                                                <li>
                                                    <a href="javascript:;" *ngIf="'Pages.Administration.Users.Delete' | permission"
                                                       (click)="deleteUser(record)">{{'Delete' | localize}}</a>
                                                </li>
                                                <li >
                                                    <a href="javascript:;" *ngIf="entityHistoryEnabled"
                                                       (click)="showHistory(record)">{{l('History')}}</a>
                                                </li>

                                            </ul>
                                        </div>
                                    </td>
                                    <td style="width: 150px" *ngIf="!appSession.tenantId">
                                        {{record.userName}}
                                    </td>
                                    <td style="width: 150px">
                                        {{record.name}}
                                    </td>
                                    <td style="width: 150px">
                                        {{record.surname}}
                                    </td>
                                    <td style="width: 150px">
                                        {{record.userName}}
                                    </td>
                                    <td style="width: 150px">
                                        {{getRolesAsString(record.roles)}}
                                    </td>
                                    <td style="width: 250px">
                                        {{record.emailAddress}}
                                    </td>
                                    <td style="width: 250px">
                                        {{record.cpf}}
                                    </td>
                                    <td style="width: 200px">
                                        <span class="kt-badge kt-badge--success kt-badge--inline" *ngIf="record.isEmailConfirmed">{{'Yes' | localize}}</span>
                                        <span class="kt-badge kt-badge--dark kt-badge--inline" *ngIf="!record.isEmailConfirmed">{{'No' | localize}}</span>
                                    </td>
                                    <td style="width: 100px">
                                        <span class="kt-badge kt-badge--success kt-badge--inline" *ngIf="record.isActive">{{'Yes' | localize}}</span>
                                        <span class="kt-badge kt-badge--dark kt-badge--inline" *ngIf="!record.isActive">{{'No' | localize}}</span>
                                    </td>
                                    <td style="width: 200px">
                                        {{record.creationTime | momentFormat:'L'}}
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{'NoData' | localize}}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage"
                                         #paginator
                                         (onPageChange)="getUsers($event)"
                                         [totalRecords]="primengTableHelper.totalRecordsCount"
                                         [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">

                            </p-paginator>
                            <span class="total-records-count">
                                {{'TotalRecordsCount' | localize:primengTableHelper.totalRecordsCount}}
                            </span>
                        </div>
                    </div>
                    <!--<Primeng-TurboTable-End>-->
                </div>
            </div>
        </div>
    </div>

    <app-create-sap-user-modal #createSapUserModal></app-create-sap-user-modal>
    <createOrEditUserModal #createOrEditUserModal (modalSave)="getUsers()"></createOrEditUserModal>
    <editUserPermissionsModal #editUserPermissionsModal></editUserPermissionsModal>
    <maquinasUserModalComponent #maquinasUserModalComponent></maquinasUserModalComponent>

    <entityTypeHistoryModal #entityTypeHistoryModal></entityTypeHistoryModal>
</div>
