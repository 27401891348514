import { Component, EventEmitter, Injector, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AgendamentoDeployDto, AlteracaoDeStatusDaDemandaDto, ConfigDeployAutomaticoServiceProxy, CreateOrEditGestaoDeMudancaNativaDto, GestaoMudancaNativaServiceProxy, JanelaDeDeployServiceProxy, TipoMudanca, TransportDataInExceptionDto } from '@shared/service-proxies/service-proxies';
import moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-information-deploy-modal',
  templateUrl: './informationDeploy.component.html',
  styleUrls: ['./informationDeploy.component.less'],
  encapsulation: ViewEncapsulation.None
})

export class InformationDeployModalComponent extends AppComponentBase {
  @ViewChild('informationDeployModal', { static: true }) modal: ModalDirective;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();

  returnDeploy: string;
  gmudNative: CreateOrEditGestaoDeMudancaNativaDto = new CreateOrEditGestaoDeMudancaNativaDto();
  demandAlteration: AlteracaoDeStatusDaDemandaDto = new AlteracaoDeStatusDaDemandaDto();
  titleModal: string;
  deploy: boolean;
  transportDate: moment.Moment;

  constructor(injector: Injector,
              private _service: GestaoMudancaNativaServiceProxy,
              private _janelaDeployAppService: JanelaDeDeployServiceProxy
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  async show(information: string, gmud: CreateOrEditGestaoDeMudancaNativaDto, demand: AlteracaoDeStatusDaDemandaDto, deploy: boolean, transportDate: moment.Moment) {
    this.deploy = deploy;
    this.demandAlteration = demand;
    this.transportDate = transportDate;

    if (!deploy){
      this.titleModal = 'Período de "Freezing"';
      this.returnDeploy = information;
      this.gmudNative = gmud;
    }else{
      this.titleModal = 'Período de "Freezing"';
      this.returnDeploy = information;
    }

    this.modal.show();
  }

  close(): void {
    this.modal.hide();
    this.closed.emit();
  }

  transportInException(): void {
    let transportDataInExceptionDto: TransportDataInExceptionDto = new TransportDataInExceptionDto();
    transportDataInExceptionDto.demandaId = this.demandAlteration.demandaId;

    if (this.deploy){
      transportDataInExceptionDto.id = "00000000-0000-0000-0000-000000000000";
      transportDataInExceptionDto.transportType = TipoMudanca.Manual;
      this._janelaDeployAppService.insertUpdateTransportInException(transportDataInExceptionDto)
          .subscribe(() => { 
            this.modal.hide();
            this.modalSave.emit(null);
          });
    }else{
      this._service.createOrUpdateGestaoMudancaNativa(this.gmudNative)
          .subscribe(() => {
            let transportDataInExceptionDto: TransportDataInExceptionDto = new TransportDataInExceptionDto();
            transportDataInExceptionDto.demandaId = this.demandAlteration.demandaId;
            transportDataInExceptionDto.transportType = this.gmudNative.tipoMudanca;
            transportDataInExceptionDto.dateTimeTransport = this.transportDate;
            this._janelaDeployAppService.insertUpdateTransportInException(transportDataInExceptionDto)
                .subscribe(() => { 
                  this.modal.hide();
                  this.modalSave.emit(this.demandAlteration);
                  this.closed.emit();
          });
      });
    }
  }
}
