<div [@routerTransition]>
    <div class="kt-subheader kt-grid__item">
        <div class="kt-subheader__main">
            <h3 class="kt-subheader__title">
                <span>{{l("Suporte")}}</span>
            </h3>
            <span class="kt-subheader__separator kt-subheader__separator--v"></span>
            <span class="kt-subheader__desc">
                {{l("SupportHeaderInfo")}}
            </span>
        </div>
        <div class="kt-subheader__toolbar">
            <div class="kt-subheader__wrapper">

            </div>
        </div>
    </div>
    <div class="kt-content">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
                <form class="kt-form" autocomplete="off">
                    <!--<div [innerHTML]="l('SupportContent1')"></div>-->
                    <div>
                        <p>Para abrir chamados de suporte você pode enviar e-mail para:</p>
                        <p><a>E-mail: <u>support@qametrik.com</u></a></p>
                        <p>Caso você queira conversar com um de nossos analistas, você pode clicar no ícone <b>(?) - Ajuda</b>.<br>
                        Esse botão de (?) está disponível no canto inferior direito na página de suporte.
                        </p>
                        <p>Caso não possua usuário no Portal de suporte  <a class="font-weight-bold underline pointer"  (click)="createUserTiflux()">clique aqui</a>, você receberá um e-mail para ativação do usuário. <br>
                        Para criar um novo chamado <a class="font-weight-bold underline pointer" href="https://suporte.qametrik.com" target="_blank">clique aqui</a>, você será redirecionado para nossa página de suporte.
                        </p>
                    </div> 
                </form>
                <form class="kt-form" autocomplete="off">
                    <div *ngIf="warning" class="userWarning">
                        <p>Usuário já cadastrado no portal de suporte.</p>
                    </div> 
                    <div *ngIf="sucess" class="userSucess">
                        <p>E-mail enviado com sucesso.</p>
                    </div> 
                </form>
            </div>
        </div>
    </div>
</div>