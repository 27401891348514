import { Component, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CreateOrUpdateUserInput, PasswordComplexitySetting, ProfileServiceProxy, UserEditDto, UserRoleDto, UserServiceProxy, FornecedoresServiceProxy, Select2ItemDto, CargosServiceProxy, SapUsersServiceProxy, TenantSettingsServiceProxy, TenantSettingsEditDto } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import * as uuid from 'uuid';
import { ValidatorsLegaisService } from '@shared/utils/validators-legais.service';

@Component({
    selector: 'createOrEditUserModal',
    templateUrl: './create-or-edit-user-modal.component.html',
    styles: [`.user-edit-dialog-profile-image {
             margin-bottom: 20px;
        }`
    ],
    styleUrls: ['./create-or-edit-user-modal.less'],

})
export class CreateOrEditUserModalComponent extends AppComponentBase {

    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;
    canChangeUserName = true;
    isTwoFactorEnabled: boolean = this.setting.getBoolean('Abp.Zero.UserManagement.TwoFactorLogin.IsEnabled');
    isLockoutEnabled: boolean = this.setting.getBoolean('Abp.Zero.UserManagement.UserLockOut.IsEnabled');
    passwordComplexitySetting: PasswordComplexitySetting = new PasswordComplexitySetting();

    user: UserEditDto = new UserEditDto();
    roles: UserRoleDto[];
    sendActivationEmail = true;
    setRandomPassword = false;
    passwordComplexityInfo = '';
    profilePicture: string;
    fornecedores: Select2ItemDto[];
    fornecedorSelected: Select2ItemDto;
    cargos: Select2ItemDto[];
    cargosSelected: Select2ItemDto;
    usuariosSap: Select2ItemDto[];
    usuarioSapSelected: Select2ItemDto;
    backdrop = false;
    dataDeExpiracaoDeAcesso: Date = null;
    isAdmin = this.appSession.user.userName.includes('admin.');

    cpfValido: boolean;
    cpfUnico: boolean;
    isNew: boolean;
    oldCpf: string;

    fornecedorInativo: boolean;
    usuariosSapsDisponiveis = new Array<Select2ItemDto>();
    usuariosSapBuffer = new Array<Select2ItemDto>();
    bufferSize = 20;
    numberOfItemsFromEndBeforeFetchingMore = 20;



    cpfJaPreenchido = false;
    constructor(
        injector: Injector,
        private _userService: UserServiceProxy,
        private _profileService: ProfileServiceProxy,
        private _fornecedoresServiceProxy: FornecedoresServiceProxy,
        private _cargosService: CargosServiceProxy,
        private _usuariosSapService: SapUsersServiceProxy,
        private _validatorsLegaisService: ValidatorsLegaisService,
        private _tenantService: TenantSettingsServiceProxy
    ) {
        super(injector);
    }

    validarCPF(): void {
        this.cpfValido = this._validatorsLegaisService.ValidaCpf(this.user.cpf);
        if (this.cpfValido) {
            this._userService.getCpfIsUnique(this.user.cpf).subscribe(result => {
                if (this.isNew)
                    this.cpfUnico = result == 0;
                else {
                    if (this.user.cpf != this.oldCpf)
                        this.cpfUnico = !(result == 1);
                }
            });
        }
        else
            this.cpfUnico = true;
    }

    validarFornecedor(val): void {
        if (val != null)
            this.fornecedorInativo = !val.active;
        else
            this.fornecedorInativo = false;
    }

    show(userId?: number): void {

        this.isNew = false;
        this.cpfUnico = true;
        if (!userId) {
            this.isNew = true;
            this.active = true;
            this.setRandomPassword = true;
            this.sendActivationEmail = true;

        }
        this.cpfValido = true;

        this.canChangeUserName = true;
        this.fornecedores = null;
        this.fornecedorSelected = null;
        this.cargos = null;
        this.cargosSelected = null;
        this.usuarioSapSelected = null;
        this._fornecedoresServiceProxy.getByName('').subscribe(x => {
            this.fornecedores = x;
        });
        this._cargosService
            .getAllCargos()
            .subscribe(result => {
                this.cargos = result;
            });
        if (userId) {

            this._fornecedoresServiceProxy.getByUsuarioId(userId).subscribe(x => {
                if (x) {
                    this.fornecedorSelected = x[0];
                    this.fornecedorInativo = !x[0].active;
                }
            });
            this._cargosService.getCargoByUserId(userId).subscribe(result => {
                this.cargosSelected = result;
            });
        }

        this.buscarUsuariosSAPs();

        this._userService.getUserForEdit(userId).subscribe(userResult => {

            this.user = userResult.user;
            this.roles = userResult.roles;
            this.cpfJaPreenchido = this.user.cpf != null;
            this.oldCpf = this.user.cpf;
            this.getProfilePicture(userResult.profilePictureId);

            if (userId) {
                this.active = true;
                this.canChangeUserName = !this.user.userName.includes('admin.');
                if (!this.appSession.tenantId) {
                    setTimeout(() => {
                        this.setRandomPassword = false;
                    }, 0);

                }
                if (this.user.dataDeExpiracaoDeAcesso) {
                    this.dataDeExpiracaoDeAcesso = this.user.dataDeExpiracaoDeAcesso.toDate();
                } else {
                    this.dataDeExpiracaoDeAcesso = null;
                }

                this.sendActivationEmail = false;
                if (this.user.usuarioSapId) {
                    this._usuariosSapService.buscarPeloUsuarioSapId(this.user.usuarioSapId).subscribe(result => {
                        this.usuarioSapSelected = result;
                    });
                }
            }

            this._profileService.getPasswordComplexitySetting().subscribe(passwordComplexityResult => {
                this.passwordComplexitySetting = passwordComplexityResult.setting;
                this.setPasswordComplexityInfo();
                this.modal.show();
            });
        });

        // if (!userId) {
        //     this._tenantService.getAllSettings().subscribe(res => {
        //         this.user.isActive = res.userManagement.isNewRegisteredUserActiveByDefault;
        //    }
        //);

        //}
    }
    buscarUsuariosSAPs() {
        this.backdrop = false;
        if (this.appSession.tenantId) {
            this._usuariosSapService.getAllSapUsers().subscribe(res => {
                this.usuariosSapsDisponiveis = res;
                this.usuariosSapBuffer = this.usuariosSapsDisponiveis.slice(0, this.bufferSize);
            });
        }
    }
    setPasswordComplexityInfo(): void {

        this.passwordComplexityInfo = '<ul>';

        if (this.passwordComplexitySetting.requireDigit) {
            this.passwordComplexityInfo += '<li>' + this.l('PasswordComplexity_RequireDigit_Hint') + '</li>';
        }

        if (this.passwordComplexitySetting.requireLowercase) {
            this.passwordComplexityInfo += '<li>' + this.l('PasswordComplexity_RequireLowercase_Hint') + '</li>';
        }

        if (this.passwordComplexitySetting.requireUppercase) {
            this.passwordComplexityInfo += '<li>' + this.l('PasswordComplexity_RequireUppercase_Hint') + '</li>';
        }

        if (this.passwordComplexitySetting.requireNonAlphanumeric) {
            this.passwordComplexityInfo += '<li>' + this.l('PasswordComplexity_RequireNonAlphanumeric_Hint') + '</li>';
        }

        if (this.passwordComplexitySetting.requiredLength) {
            this.passwordComplexityInfo += '<li>' + this.l('PasswordComplexity_RequiredLength_Hint', this.passwordComplexitySetting.requiredLength) + '</li>';
        }

        this.passwordComplexityInfo += '</ul>';
    }

    getProfilePicture(profilePictureId: string): void {
        if (!profilePictureId) {
            this.profilePicture = this.appRootUrl() + 'assets/common/images/default-profile-picture.png';
        } else {
            this._profileService.getProfilePictureById(profilePictureId).subscribe(result => {

                if (result && result.profilePicture) {
                    this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
                } else {
                    this.profilePicture = this.appRootUrl() + 'assets/common/images/default-profile-picture.png';
                }
            });
        }
    }

    onShown(): void {
        document.getElementById('Name').focus();
    }

    save(): void {
        let input = new CreateOrUpdateUserInput();
        input.user = this.user;
        input.user.fornecedorId = null;
        input.user.usuarioSapId = null;
        input.user.cargoId = null;

        if (this.fornecedorSelected) {
            input.user.fornecedorId = this.fornecedorSelected.value;
        }

        if (this.cargosSelected) {
            input.user.cargoId = this.cargosSelected.value;
        }

        if (this.usuarioSapSelected) {
            input.user.usuarioSapId = this.usuarioSapSelected.value;
        }

        if (this.dataDeExpiracaoDeAcesso) {
            this.user.dataDeExpiracaoDeAcesso = moment(
                this.dataDeExpiracaoDeAcesso
            ).startOf('day');
        }
        else {
            this.user.dataDeExpiracaoDeAcesso = null;
        }

        input.setRandomPassword = this.setRandomPassword;
        input.sendActivationEmail = this.sendActivationEmail;
        input.assignedRoleNames =
            _.map(
                _.filter(this.roles, { isAssigned: true }), role => role.roleName
            );
        if (this.user.id == null && this.appSession.tenantId) {
            this.user.userName = uuid.v4();
        }
        this.saving = true;
        this._userService.createOrUpdateUser(input)
            .pipe(finalize(() => { this.saving = false; }))
            .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
            });
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    getAssignedRoleCount(): number {
        return _.filter(this.roles, { isAssigned: true }).length;
    }

    abrirModalCriarNovoUsuarioSap(ev: any) {
        ev.preventDefault();
        this.backdrop = true;
    }

    onScroll({ end }) {
        if (this.usuariosSapsDisponiveis.length <= this.usuariosSapBuffer.length) {
            return;
        }

        if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.usuariosSapBuffer.length) {
            this.fetchMore();
        }
    }

    fetchMore() {
        const len = this.usuariosSapBuffer.length;
        const more = this.usuariosSapsDisponiveis.slice(len, this.bufferSize + len);
        this.usuariosSapBuffer = this.usuariosSapBuffer.concat(more);
    }
}
