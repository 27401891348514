import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { SupportServiceProxy } from '@shared/service-proxies/service-proxies';
@Component({
  selector: 'app-suporte',
  templateUrl: './suporte.component.html',
  styleUrls: ['./suporte.component.css'],
  animations: [appModuleAnimation()]
})
export class SuporteComponent extends AppComponentBase implements OnInit, OnDestroy {
  
  js = null;
  warning: Boolean = false;
  sucess: Boolean = false;

  constructor(injector: Injector,
              private _supportServiceProxy: SupportServiceProxy) {  super(injector)}

  ngOnDestroy(): void {
      const chat = document.getElementById('tiflux--chat');
      chat.style.visibility = 'hidden'; // Oculta o componente do chat ao sair da página
  }

  ngOnInit(): void {
    var organization_token = "3bc8405861ed0b80a16e944f43eb4624deb6ed72";
    (window as any).organization_token = organization_token;
    this.js = document.createElement("script");
    this.js.src = `https://public-assets.tiflux.com/chat_widget.js?organization_token=${organization_token}`;
    document.head.appendChild(this.js)
    const chat = document.getElementById('md-app-widget')
    chat.style.visibility = 'visible';
  }
  
  openLink(){
    window.open("https://support.qametrik.com/Ticket/Create");
  }

  createUserTiflux(){
    this._supportServiceProxy.createUserTiflux()
        .subscribe(res => {
          if (res){
            this.sucess = true;
            setTimeout(() => {
              this.sucess = false;
            }, 5000);
          }else{
            this.warning = true;
            setTimeout(() => {
              this.warning = false;
            }, 5000);
          }     
        });
  }
}
