<div bsModal #changeManagerModal="bs-modal" class="modal fade change-modal" tabindex="-1" role="dialog"
    aria-labelledby="modal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="card flex justify-content-center gap-2">
        <p-toast></p-toast>
    </div>
    <div class="modal-dialog modal-xl">
        <div class="modal-content change-modal">
            <form #changeManagerForm="ngForm" (ngSubmit)="save()" autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title">
                        {{"CreateChangeManagerHeader" | localize}}
                    </h4>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="'Close' | localize">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p-progressSpinner *ngIf="loading"></p-progressSpinner>
                    <!--
                    <tabset *ngIf="!loading">
                        <tab heading="{{'DadosGerais' | localize}}">
                        -->
                            <!--
                            <div class="form-group">
                                <label for="change-title">{{"ChangeTitle" | localize}} *</label>
                                <input id="change-title" #changeTitle="ngModel" type="text" name="ChangeTitle"
                                    class="form-control" [(ngModel)]="changeManager.titulo" required
                                    [ngClass]="{'is-invalid': changeTitle.invalid, 'is-valid': !changeTitle.invalid}" />
                                <validation-messages [formCtrl]="changeTitle"></validation-messages>
                            </div>
                            -->
                            <div class="form-group">
                                <label for="change-title">{{"ChangeTitle" | localize}} *</label>
                                <input id="change-title" #changeTitle="ngModel" type="text" name="ChangeTitle"
                                    class="form-control" [(ngModel)]="changeManager.titulo" disabled />
                            </div>
                            <div class="form-group">
                                <label for="change-description">{{"Descrição Demanda" | localize}} *</label>
                                <textarea id="change-description" #changeDescription="ngModel" type="text"
                                    name="ChangeDescription" class="form-control textarea"
                                    [(ngModel)]="changeManager.descricao" disabled></textarea>
                            </div>
                            <div class="form-group">
                                <label for="change-uDescription">{{"ChangeDescription" | localize}} *</label>
                                <textarea id="change-uDescription" #changeDescription="ngModel" type="text"
                                    name="ChangeUDescription" class="form-control textarea"
                                    [(ngModel)]="changeManager.uDescricao"></textarea>
                            </div>
                            <div class="form-group">
                                <label for="change-comements">{{"ChangeComments" | localize}} *</label>
                                <textarea id="change-comements" #changeComments="ngModel" type="text"
                                    name="ChangeComments" class="form-control textarea"
                                    [(ngModel)]="changeManager.comentario"></textarea>
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-4 d-flex flex-column mb-2">
                                    <label for="change-start">{{"ChangeStart" | localize}} *</label>
                                    <p-calendar id="change-start" name="ChangeStart" [(ngModel)]="changeManager.dataInicio"
                                        [showTime]="true" dateFormat="dd/mm/yy" [showIcon]="true" #changeStart="ngModel">
                                    </p-calendar>                                  
                                    <validation-messages [formCtrl]="changeStart"></validation-messages>                                    
                                </div>
                                <div class="col-12 col-md-4 d-flex flex-column mb-2">
                                    <label for="change-end">{{"ChangeEnd" | localize}} *</label>
                                    <p-calendar id="change-end" name="ChangeEnd" [(ngModel)]="changeManager.dataFim"
                                        [showTime]="true" dateFormat="dd/mm/yy" [showIcon]="true" #changeEnd="ngModel">
                                    </p-calendar>                                  
                                    <validation-messages [formCtrl]="changeEnd"></validation-messages>                                  
                                </div>
                            </div>                          
                            <div class="form-group">
                                <label for="return-plan">{{"ReturnPlan" | localize}} *</label>                                   
                                <input id="return-plan" #returnPlan="ngModel" type="text" name="ReturnPlan"
                                    class="form-control" [(ngModel)]="changeManager.planoRetorno" />
                            </div>
                            <!--
                            <app-enum-select class="form-group" sName="ChangeNature" sId="ChangeNature"
                                sClass="form-control" [label]="l('ChangeNature')" [value]="changeManager.natureza"
                                (valueChange)="changeManager.natureza = $event" [enumeration]="enumNautrezaGmud">
                            </app-enum-select>
                            -->
                            <!--
                            <div class="form-group">
                                <label for="change-requester">{{"ChangeRequester" | localize}} *</label>
                                <input id="change-requester" #changeRequester="ngModel" type="text"
                                    name="ChangeRequester" class="form-control" [(ngModel)]="changeManager.solicitante"
                                    [ngClass]="{'is-invalid': changeRequester.invalid, 'is-valid': !changeRequester.invalid}"
                                    placeholder="99999@localiza.com" />
                                <validation-messages [formCtrl]="changeRequester"></validation-messages>
                            </div>
                            -->
                            <!--
                            <div class="form-group">
                                <label for="change-type">{{"ChangeType" | localize}} *</label>
                                <input id="change-type" #changeType="ngModel" type="text" name="ChangeType"
                                    class="form-control" [(ngModel)]="changeManager.tipoMudanca" disabled />
                            </div>
                            -->
                            <!--
                            <div class="form-group">
                                <label for="change-category">{{"ChangeCategory" | localize}} *</label>
                                <input id="change-category" #changeCategory="ngModel" type="text" name="ChangeCategory"
                                    class="form-control" [(ngModel)]="changeManager.categoria" disabled />
                            </div>
                            -->
                            <!--
                            <div class="d-flex">
                                <div class="form-group">
                                    <label for="change-start">{{"ChangeStart" | localize}} *</label>
                                    <input id="change-start" #changeStart="ngModel" type="text" name="ChangeStart"
                                        class="form-control" [(ngModel)]="changeManager.dataInicio" required
                                        [ngClass]="{'is-invalid': changeStart.invalid, 'is-valid': !changeStart.invalid}"
                                        bsDatepicker />
                                    <validation-messages [formCtrl]="changeStart"></validation-messages>
                                </div>
                                <app-custom-timepicker [value]="changeManager.dataInicio" class="mt-2 ml-5"
                                    (valueChange)="dataInicio = $event"></app-custom-timepicker>
                            </div>
                            -->
                            <!--
                            <div class="d-flex">
                                <div class="form-group">
                                    <label for="change-end">{{"ChangeEnd" | localize}} *</label>
                                    <input id="change-end" #changeEnd="ngModel" type="text" name="ChangeEnd"
                                        class="form-control" [(ngModel)]="changeManager.dataFim" required
                                        [ngClass]="{'is-invalid': changeEnd.invalid, 'is-valid': !changeEnd.invalid}"
                                        bsDatepicker />
                                    <validation-messages [formCtrl]="changeEnd"></validation-messages>
                                </div>
                                <app-custom-timepicker [value]="changeManager.dataFim" class="mt-2 ml-5"
                                    (valueChange)="dataFim = $event"></app-custom-timepicker>
                            </div>
                            -->
                            <!--
                            <app-custom-select fieldId="designed-group" [label]="'DesignedGroup' | localize"
                                fieldName="DesignedGroup" [options]="assignmentGroups" valueKey="name" [required]="true"
                                labelKey="name" [value]="changeManager.grupoAtribuicao"
                                (valueChange)="changeManager.grupoAtribuicao = $event"></app-custom-select>
                            -->
                            <!--
                            <app-custom-select fieldId="configuration-item"
                                [label]="'ConfigurationItemField' | localize" fieldName="ConfigurationItem"
                                [options]="configurationItems" valueKey="name" [required]="true" labelKey="name"
                                [value]="changeManager.itemConfiguracao"
                                (valueChange)="changeManager.itemConfiguracao = $event">
                            </app-custom-select>
                            -->
                            <!--
                            <div class="form-group">
                                <label for="change-base">{{"ChangeBase" | localize}} *</label>
                                <input id="change-base" #changeBase="ngModel" type="text" name="ChangeBase"
                                    class="form-control" [(ngModel)]="changeManager.acessoBase" disabled />
                            </div>
                            -->
                            <!--
                            <div class="form-group">
                                <label for="change-justification">{{"ChangeJustification" | localize}} *</label>
                                <input id="change-base" #changeJustification="ngModel" type="text"
                                    name="ChangeJustification" class="form-control"
                                    [(ngModel)]="changeManager.justificativa" disabled />
                            </div>
                            -->
                            <!--
                            <div class="form-group">
                                <label for="change-description">{{"ChangeDescription" | localize}} *</label>
                                <textarea id="change-description" #changeDescription="ngModel" type="text"
                                    name="ChangeDescription" class="form-control textarea"
                                    [(ngModel)]="changeManager.descricao" required
                                    [ngClass]="{'is-invalid': changeDescription.invalid, 'is-valid': !changeDescription.invalid}"></textarea>
                                <validation-messages [formCtrl]="changeDescription"></validation-messages>
                            </div>
                            -->
                        <!--
                        </tab>
                        -->
                        <!--
                        <tab heading="{{'ChangeRisk' | localize}}">
                            <div class="form-group">
                                <label for="change-risk">{{"ChangeBusinessRisk" | localize}} *</label>
                                <input id="change-risk" #changeRisk="ngModel" type="text" name="ChangeRisk"
                                    class="form-control" [(ngModel)]="changeManager.gestaoMudancaRisco.risco" required
                                    [ngClass]="{'is-invalid': changeRisk.invalid, 'is-valid': !changeRisk.invalid}" />
                                <validation-messages [formCtrl]="changeRisk"></validation-messages>
                            </div>
                            <app-enum-select sName="ChangeBusinessRisk" sId="change-business-risk" sClass="form-control"
                                [label]="'ChangeRisk' | localize" [value]="changeManager.risco"
                                (valueChange)="changeManager.risco = $event" [enumeration]="enumRiscoGmud"
                                (valid)="validations.risk = $event">
                            </app-enum-select>
                            <div class="form-group">
                                <label for="return-plan">{{"ReturnPlan" | localize}} *</label>
                                <input id="return-plan" #returnPlan="ngModel" type="text" name="ReturnPlan"
                                    class="form-control" [(ngModel)]="changeManager.planoRetorno" disabled />
                            </div>
                            <div class="form-group">
                                <label for="test-plan">{{"TestPlan" | localize}} *</label>
                                <input id="test-plan" #testPlan="ngModel" type="text" name="TestPlan"
                                    class="form-control" [(ngModel)]="changeManager.gestaoMudancaRisco.planoTeste"
                                    disabled />
                            </div>
                            <div class="form-group">
                                <label for="mitigation-plan">{{"MitigationPlan" | localize}}*</label>
                                <input id="mitigation-plan" #mitigationPlan="ngModel" type="text" name="MitigationPlan"
                                    class="form-control" [(ngModel)]="changeManager.gestaoMudancaRisco.planoMitigacao"
                                    required
                                    [ngClass]="{'is-invalid': mitigationPlan.invalid, 'is-valid': !mitigationPlan.invalid}" />
                                <validation-messages [formCtrl]="mitigationPlan"></validation-messages>
                            </div>
                            <div class="form-group">
                                <label for="non-publication-effect">{{"NonPublicationEffect" | localize}} *</label>
                                <input id="non-publication-effect" #nonPublicationEffect="ngModel" type="text"
                                    name="NonPublicationEffect" class="form-control"
                                    [(ngModel)]="changeManager.gestaoMudancaRisco.efeitoNaoPublicacao" required
                                    [ngClass]="{'is-invalid': nonPublicationEffect.invalid, 'is-valid': !nonPublicationEffect.invalid}" />
                                <validation-messages [formCtrl]="nonPublicationEffect"></validation-messages>
                            </div>
                            <div class="form-group">
                                <label for="change-plan">{{"ChangePlan" | localize}} *</label>
                                <input id="change-plan" #changePlan="ngModel" type="text" name="ChangePlan"
                                    class="form-control" [(ngModel)]="changeManager.gestaoMudancaRisco.planoMudanca"
                                    disabled />
                            </div>
                        </tab>
                    </tabset>
                    -->
                </div>

                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{"Cancel" |
                        localize}}</button>
                    <!--
                    <button type="button" class="btn btn-outline-primary" (click)="manualChangeEvent()">{{"ManualChange"
                        |
                        localize}}</button>
                    -->
                    <!--
                    <button type="submit" class="btn btn-primary blue" [buttonBusy]="saving"
                        [disabled]="changeManagerForm.invalid || isInvalid"
                        [busyText]="'SavingWithThreeDot' | localize"><i class="fa fa-save"></i>
                        <span>{{"Save" | localize}}</span>
                    </button>
                    -->
                    <button type="submit" class="btn btn-primary blue"><i class="fa fa-save"></i>
                    <span>{{"Save" | localize}}</span>
                </button>
                </div>
            </form>
        </div>
    </div>
</div>